import http from 'http';
import https from 'https';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import immutable from 'immutable';
import Logger from './logger';

export class AxiosHttpClient {
  private static _instance: AxiosHttpClient;
  private axiosInstance: AxiosInstance;

  private constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  /**
   * @returns {AxiosHttpClient}
   */
  public static get instance(): AxiosHttpClient {
    if (!this._instance) {
      const axiosInstance = axios.create({
        httpAgent: new http.Agent({ keepAlive: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),
      });

      this._instance = new AxiosHttpClient(axiosInstance);
    }

    return this._instance;
  }

  /**
   * @returns {Promise<AxiosResponse>}
   */
  public async execute(requestConfig: RequestConfig): Promise<AxiosResponse> {
    return (await this.axiosInstance.request(requestConfig.build()).catch((error: AxiosError) => {
      // 削除API でレスポンスが空だとパースエラーになるので成功レスポンスとして返す
      if (error.code === 'HPE_INVALID_CONSTANT' && requestConfig.method === 'DELETE') {
        return Promise.resolve({ status: 204, data: {} });
      }

      if (!error.response) {
        // TODO axios の createError が落ち着いたら削除する
        Logger.error(new Error(JSON.stringify(requestConfig.toJS())));
        Logger.error(error);
        throw error;
      }

      throw error.response;
    })) as AxiosResponse;
  }

  /**
   * @param {Resopnse} defaultValue
   * @returns {Promise<Response>}
   */
  public async request<Response>(requestConfig: RequestConfig, defaultValue: Response): Promise<Response> {
    const ResponseRecord: immutable.Record.Factory<Response> = immutable.Record(defaultValue);
    const axiosResponse = await this.execute(requestConfig);

    return new ResponseRecord(axiosResponse.data).toJS();
  }
}

type RequestConfigType = {
  headers: immutable.Map<string, string>;
  method: Type.Method;
  url: string;
  body: object;
  adapter: any;
};

const defaultValue = {
  headers: immutable.Map<string, string>(),
  method: undefined,
  url: '',
  body: {},
  adapter: undefined,
};

const RequestConfigRecord: immutable.Record.Factory<RequestConfigType> = immutable.Record(defaultValue);
export class RequestConfig extends RequestConfigRecord {
  /**
   * axios の RequestConfig を作成する
   *
   * @returns {AxiosRequestConfig}
   */
  public build(): AxiosRequestConfig {
    const config: AxiosRequestConfig = {
      method: this.method,
      url: this.url,
      headers: this.headers.toJS(),
    };

    if (Object.keys(this.body).length !== 0) {
      config.data = this.body;
    }

    if (typeof this.adapter === 'function') {
      config.adapter = this.adapter;
    }

    return config;
  }

  /**
   * url を連結する
   *
   * @param {string} url
   * @returns {RequestConfig}
   */
  public concatUrl(url: string): RequestConfig {
    return this.set('url', `${this.url}${url}`);
  }

  /**
   * header を追加する
   *
   * @param {[key: string]: string} headers
   * @returns {RequestConfig}
   */
  public addHeaders(headers: { [key: string]: string }): RequestConfig {
    return this.set('headers', this.headers.merge(immutable.Map(headers)));
  }
}
