import immutable from 'immutable';
import { Config } from '../constants/constant';
import { RequestConfig } from '../libs/axios';
import { CommentApiInterface } from '../repositories/CommentRepository';
import { CommentApiInterface as MyCommentApiInterface } from '../repositories/MyRepository';
import * as Pager from '../domain/Pager';
import * as Comment from '../domain/Comment';
import * as MyComment from '../domain/MyComment';
import * as Heart from '../domain/Heart';
import * as Validation from '../domain/Validation';
import * as Comments from './comments';

export default class CommentsApi implements CommentApiInterface, MyCommentApiInterface {
  private api: Comments.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Comments.Api(requestConfig);
  }

  /**
   * @param {number} userId
   * @param {Comment.Value} value
   * @returns {Promise<Validation.Value>}
   */
  public confirm(userId: number, value: Comment.Value): Promise<Validation.Value> {
    const request = {
      user_id: userId,
      topic_id: value.topicId,
      comment: value.content,
    };

    return this.api
      .confirm()
      .execute(request)
      .then(() => new Validation.Value())
      .catch(error => {
        if (!error.status || error.status !== 422) {
          throw error;
        }

        return Promise.resolve(new Validation.Value(error.data));
      });
  }

  /**
   * @param {number} userId
   * @param {Comment.Value} value
   * @returns {Promise<Comment.Entity>}
   */
  public async store(userId: number, value: Comment.Value): Promise<Comment.Entity> {
    const request = {
      user_id: userId,
      topic_id: value.topicId,
      comment: value.content,
    };
    const response = await this.api.store().execute(request);

    return this.convertResponseToEntity(response);
  }

  /**
   * @param {number} userId
   * @param {number} topicId
   * @param {Pager.Value} pager
   * @returns {Promise<Comment.EntityList>}
   */
  public async fetchList(userId: number, topicId: number, pager: Pager.Value): Promise<Comment.EntityList> {
    const request = {
      user_id: userId,
      topic_id: topicId,
      sort: Config.COMMENT_SORT_MAP.get(pager.filter.get('sort'), '-created_at'),
      page: pager.page,
      per_page: pager.perPage,
    };
    const response = await this.api.fetchList().execute(request);

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {number} topicId
   * @param {Pager.Value} pager
   * @returns {Promise<Comment.EntityList>}
   */
  public async fetchGuestList(topicId: number, pager: Pager.Value): Promise<Comment.EntityList> {
    const request = {
      topic_id: topicId,
      sort: Config.COMMENT_SORT_MAP.get(pager.filter.get('sort'), '-created_at'),
      page: pager.page,
      per_page: pager.perPage,
    };
    const response = await this.api.fetchGuestList().execute(request);

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {number} userId
   * @param {Pager.Value} pager
   * @returns {Promise<MyComment.EntityFetchList>}
   */
  public async fetchMyList(userId: number, pager: Pager.Value): Promise<MyComment.EntityList> {
    const request = {
      user_id: userId,
      sort: Config.COMMENT_SORT_MAP.get(pager.filter.get('sort'), '-created_at'),
      page: pager.page,
      per_page: pager.perPage,
    };
    const response = await this.api.fetchMyList().execute(request);

    return this.convertResponseToMyEntityList(response);
  }

  /**
   * @param {Type.HttpCommentListResponse} response
   * @returns {Comment.EntityList}
   */
  private convertResponseToEntityList(response: Type.HttpCommentListResponse): Comment.EntityList {
    const entityList = response.list.reduce(
      (entityList: { entities: { [key: number]: Comment.Entity }; result: number[] }, response: Type.HttpCommentResponse) => {
        entityList.entities[response.id] = this.convertResponseToEntity(response);
        entityList.result.push(response.id);

        return entityList;
      },
      { entities: {}, result: [] },
    );

    return new Comment.EntityList({
      entities: immutable.Map(entityList.entities),
      result: immutable.List(entityList.result),
      totalCount: response.total_count,
    });
  }

  /**
   * @param {Type.HttpCommentListResponse} response
   * @returns {MyComment.EntityList}
   */
  private convertResponseToMyEntityList(response: Type.HttpCommentListResponse): MyComment.EntityList {
    const entityList = response.list.reduce(
      (entityList: { entities: { [key: number]: MyComment.Entity }; result: number[] }, response: Type.HttpCommentResponse) => {
        const entity = new MyComment.Entity({
          id: response.id,
          topicId: response.topic_id,
          content: response.content,
        });

        entityList.entities[response.id] = entity;
        entityList.result.push(response.id);

        return entityList;
      },
      { entities: {}, result: [] },
    );

    return new MyComment.EntityList({
      entities: immutable.Map(entityList.entities),
      result: immutable.List(entityList.result),
      totalCount: response.total_count,
    });
  }

  /**
   * @param {Type.HttpCommentResponse} response
   * @returns {Comment.Entity}
   */
  private convertResponseToEntity(response: Type.HttpCommentResponse): Comment.Entity {
    const value = {
      topicId: response.topic_id,
      userId: response.user_id,
      sequenceNo: response.sequence_no,
      content: response.content,
      isAnonymous: response.is_anonymous,
      createdAt: response.created_at,
    };

    const commentValue = new Comment.Value(value);
    const heartValue = new Heart.Value({ count: response.heart_count });

    const comment = {
      id: response.id,
      value: commentValue,
      heart: heartValue,
    };

    return new Comment.Entity(comment);
  }
}
