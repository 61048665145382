import { RequestConfig, AxiosHttpClient } from '../../libs/axios';
import EnvConstant from '../../constants/EnvConstant';

const responseDefaultValue: Type.HttpIssueGuestTokenResponse = {
  scope: '',
  expires_in: 0,
  token_type: '',
  access_token: '',
};

export default class IssueGuestTokenApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpIssueGuestTokenRequest} request
   * @returns {Promise<Type.HttpIssueGuestTokenResponse>}
   */
  public execute(request: Type.HttpIssueGuestTokenRequest): Promise<Type.HttpIssueGuestTokenResponse> {
    const method = 'POST';
    const url = `${EnvConstant.API_GATEWAY_URL}/tokens/guest`;
    const body = request;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .set('url', url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(() => responseDefaultValue);
  }
}
