import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpUserResponse = {
  sub: 0,
  account_status: '',
  given_name: '',
  family_name: '',
  preferred_username: '',
  email: '',
  email_verified: 0,
  gender: '',
  birthdate: '',
  occupation: {
    occupation_code: '',
  },
  school: {
    id: 0,
    graduation_year: 0,
    school_type: '',
  },
};

export default class FetchMyApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * ユーザ情報はローカル環境とそれ以外で接続先のパスが異なるので
   * baseUrl にあらかじめ接続先が格納されている
   *
   * @returns {Promise<Type.HttpUserResponse>}
   */
  public execute(): Promise<Type.HttpUserResponse> {
    const method = 'GET';

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }
}
