import styled from './styled';
import * as mixin from './mixin';

/**
 * 文字装飾
 */
export interface CharacterProps extends React.HTMLProps<HTMLElement> {
  textAlign?: string;
  onClick?: () => void;
}

export const Character = styled('p')<CharacterProps>`
  margin: 0;
  ${props => mixin.fontSize(props.theme.charFontSize)};
  color: ${props => props.theme.charColor};
  ${props => (props.textAlign ? `text-align: ${props.textAlign}` : '')};
  text-decoration: ${props => props.theme.charDecoration};
`;

/**
 * ボタン
 */
interface ButtonBoxProps {
  className: string;
}

export const ButtonBox = styled('div')<ButtonBoxProps>`
  ${props => (props.theme.isSp ? '' : 'padding: 0 16%;')};
  font-size: 1rem;
  .done {
    background-color: #d64c87;
    border-right: 2px solid #b24574;
    border-left: 2px solid #b24574;
    -webkit-box-shadow: 0 4px 1px #b24574 inset, 0 2px #b24574;
    box-shadow: inset 0 4px 1px #b24574, 0 2px #b24574;
  }
  .loading {
    background-image: url(https://cdn-nurse-senka-assets.nurse-senka.jp/img/common/spinner.gif);
    background-repeat: no-repeat;
    background-position: 50%;
    color: rgba(0, 0, 0, 0) !important;
  }
`;

/**
 * 投稿、投稿確認の注意文言
 */
export const AttentionBox = styled('div')`
  margin: 20px 0;
  padding: 0 10px;
`;

/**
 * ユーザプロフィールリンク
 */
export const UserLink = styled('div')`
  a {
    display: inline-block;

    span {
      color: ${props => props.theme.linkTxtColor};
    }
  }

  img {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 4px;
    vertical-align: middle;
  }

  p {
    margin: 0;
    display: inline-block;
    color: ${props => props.theme.paleTxtColor};
    ${mixin.fontSize(10)};
    vertical-align: middle;
  }

  span {
    ${mixin.fontSize(13)};
  }
`;

/**
 * カテゴリリンク
 */
export const CategoryLink = styled('div')`
  ${mixin.fontSize(13)};
  color: ${props => props.theme.linkTxtColor};

  a {
    display: inline-block;
    text-decoration: ${props => (props.theme.isSp ? 'underline' : 'none')};

    &:hover {
      text-decoration: underline;
    }

    &::before {
      color: ${props => props.theme.iconTxtColor};
    }
  }
`;

/**
 * 絵文字入力欄
 */
export const Pictograph = styled('div')`
  padding: 5px 10px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 6px;
  background: ${props => props.theme.bgColor};
  font-size: 0;
  line-height: 0;
  text-align: left;

  span {
    margin: 5px 5px 0;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`;

/**
 * ニックネームが設定されていない時の注意文言
 */
export const EmptyName = styled('div')`
  margin: 10px;
  text-align: left;

  p {
    padding: 10px 0;
    font-size: 1rem;
  }

  a {
    display: inline !important;
    font-size: inherit !important;
  }
`;

/**
 * 一覧がないとき
 */
export const ListEmpty = styled('p')`
  margin: 20px 0;
  text-align: center;
`;

/**
 * DFP広告
 */
export const GoogleTag = styled('div')`
  margin: 20px auto;
  min-width: 320px;
  max-width: 605px;
  height: 56px;
  text-align: center;
`;

/**
 * 職レポクチコミ投稿キャンペーンボタン
 */
export const ButtonCampaign = styled('div')`
  & > p {
    margin: 0;
    color: #eb5a98;
    text-align: center;
    text-shadow: #fff 1px 1px 1px, #fff -1px 1px 1px, #fff -1px -1px 1px, #fff 1px -1px 1px, #fff 1px 0px 1px, #fff 0px 1px 1px, #fff -1px 0px 1px,
      #fff 0px -1px 1px;
    font-weight: bold;
  }

  & > .btn-box-h1 > a {
    padding: 5px 0;
    position: relative;
    background-image: linear-gradient(#f5abc4 0%, #e73682 30%);
    border: 3px #e73682 solid;
    color: #fff;
    ${mixin.fontSize(16)};
    line-height: 1.6;

    &:hover {
      background-image: linear-gradient(#f5abc4 0%, #d64c87 30%);
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 2px #b24574 inset;
      border-radius: 6px;
    }

    &::after {
      content: '';
      width: calc(100% - 1px * 2);
      height: calc(100% - 1px * 2);
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 2px 1px #d64c87, 0 2px 1px #d64c87 inset, 0 -4px 4px #d64c87 inset;
      border: 1px #ffdee9 dashed;
      border-radius: 6px;
    }

    & > p {
      margin: 0;
    }
  }
`;
