import { AuthRepositoryInterface } from '../domain/DomainInterface';
import EnvConstant from '../constants/EnvConstant';

export interface AuthApiInterface {
  issueGuestToken: (clientId: number) => Promise<string>;
  verifyAccessToken: (accessToken: string) => Promise<boolean>;
  verifyLoginSessionToken: (loginSessionToken: string) => Promise<string>;
}

export default class AuthRepository implements AuthRepositoryInterface {
  private api: AuthApiInterface;

  public constructor(api: AuthApiInterface) {
    this.api = api;
  }

  /**
   * ゲスト用のアクセストークンを返す
   *
   * @returns {Promise<string>}
   */
  public createGuestToken(): Promise<string> {
    return this.api.issueGuestToken(EnvConstant.CLIENT_ID);
  }

  /**
   * アクセストークンの検証結果を取得する
   *
   * @param {string} accessToken
   * @returns {Promise<boolean>}
   */
  public findVerifyResultAccessToken(accessToken: string): Promise<boolean> {
    return this.api.verifyAccessToken(accessToken);
  }

  /**
   * ログインセッショントークンの検証結果を取得する
   *
   * @param {string} loginSessionToken
   * @returns {Promise<string>}
   */
  public findVerifyResultLoginSessionToken(loginSessionToken: string): Promise<string> {
    return this.api.verifyLoginSessionToken(loginSessionToken);
  }
}
