import { ViolationRepositoryInterface } from '../domain/DomainInterface';
import * as Session from '../domain/Session';
import * as Violation from '../domain/Violation';
import * as Validation from '../domain/Validation';

export interface ViolationApiInterface {
  confirm(userId: number, value: Violation.Value): Promise<Validation.Value>;
  store(userId: number, value: Violation.Value): Promise<void>;
}

export default class ViolationRepository implements ViolationRepositoryInterface {
  private session: Session.Value;
  private api: ViolationApiInterface;

  public constructor(session: Session.Value, api: ViolationApiInterface) {
    this.session = session;
    this.api = api;
  }

  /**
   * 違反報告を 1件保存する
   *
   * @param {Violation.Value} value
   * @returns {Promise<void>}
   */
  public async store(value: Violation.Value): Promise<void> {
    return this.api.store(this.session.user.identifier, value);
  }

  /**
   * 違反報告の確認結果を取得する
   * 主にバリデーションの結果を取得する
   *
   * @param {Violation.Value} value
   * @returns {Promise<Validation.Value>}
   */
  public findConfirmResult(value: Violation.Value): Promise<Validation.Value> {
    return this.api.confirm(this.session.user.identifier, value);
  }
}
