import { PaneRepositoryInterface } from '../domain/DomainInterface';

export interface PaneApiInterface {
  fetchHeader(loginSessionToken: string, isSp: boolean): Promise<string>;
  fetchSide(loginSessionToken: string, isSp: boolean): Promise<string>;
  fetchFooter(loginSessionToken: string, isSp: boolean): Promise<string>;
}

export default class PaneRepository implements PaneRepositoryInterface {
  private api: PaneApiInterface;

  public constructor(api: PaneApiInterface) {
    this.api = api;
  }

  /**
   * 共通ヘッダーの Html を取得する
   *
   * @returns {Promise<string>}
   */
  public findHeader(loginSessionToken: string, isSp: boolean): Promise<string> {
    return this.api.fetchHeader(loginSessionToken, isSp);
  }

  /**
   * 共通サイドメニューの Html を取得する
   *
   * @returns {Promise<string>}
   */
  public findSide(loginSessionToken: string, isSp: boolean): Promise<string> {
    return this.api.fetchSide(loginSessionToken, isSp);
  }

  /**
   * 共通フッターの Html を取得する
   *
   * @returns {Promise<string>}
   */
  public findFooter(loginSessionToken: string, isSp: boolean): Promise<string> {
    return this.api.fetchFooter(loginSessionToken, isSp);
  }
}
