import { RequestConfig } from '../libs/axios';
import { ClipApiInterface as TopicClipApiInterface } from '../repositories/TopicRepository';
import { ClipApiInterface as MyClipApiInterface } from '../repositories/MyRepository';
import * as Pager from '../domain/Pager';
import * as Clips from './clips';

export default class ClipsApi implements TopicClipApiInterface, MyClipApiInterface {
  private api: Clips.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Clips.Api(requestConfig);
  }

  /**
   * @param {number} userId
   * @param {number} topicId
   * @returns {Promise<number>}
   */
  public async store(userId: number, topicId: number): Promise<number> {
    const request = {
      user_id: userId,
      topic_id: topicId,
    };

    return await this.api
      .store()
      .execute(request)
      .then((response: Type.HttpClipResponse) => response.id)
      .catch(() => 0);
  }

  /**
   * @param {number} userId
   * @param {Pager.Value} pager
   * @returns {Type.HttpClipListResponse}
   */
  public async fetchList(userId: number, pager: Pager.Value): Promise<Type.HttpClipListResponse> {
    const request = {
      user_id: userId,
      page: pager.page,
      per_page: pager.perPage,
    };

    return this.api.fetchList().execute(request);
  }

  /**
   * @param {number} userId
   * @param {number} topicId
   * @returns {Promise<number>}
   */
  public async search(userId: number, topicId: number): Promise<number> {
    const request = {
      user_id: userId,
      body: {
        topic_id: topicId,
      },
    };
    const response = await this.api.search().execute(request);

    return response.id;
  }

  /**
   * @param {number} userId
   * @param {number} clipId
   * @returns {Promise<void>}
   */
  public async destroy(userId: number, clipId: number): Promise<void> {
    const request = {
      user_id: userId,
      clip_id: clipId,
    };

    await this.api
      .destroy()
      .execute(request)
      .catch(() => {});
  }
}
