import immutable from 'immutable';
import Utils from '../libs/utils';
import { EntityIdentifier, EntityListInterface } from './DomainInterface';

/**
 * ページャーバリューオブジェクト
 */
type PagerValue = {
  totalCount: number;
  perPage: number;
  page: number;
  filter: immutable.Map<string, any>;
  items: immutable.List<EntityIdentifier<number>>;
};

const pagerDefaultValue = {
  totalCount: 0,
  perPage: 30,
  page: 0,
  filter: immutable.Map<string, any>(),
  items: immutable.List<EntityIdentifier<number>>(),
};

const ValueRecord: immutable.Record.Factory<PagerValue> = immutable.Record(pagerDefaultValue);
export class Value extends ValueRecord {
  /**
   * オブジェクトからバリューオブジェクトを生成する
   *
   * @param {object} data
   * @returns {Value}
   */
  public static fromJS(data: object): Value {
    const value = immutable.fromJS(data).withMutations((value: immutable.Map<string, object>) => {
      return value.updateIn(['filter'], (filter: object) => immutable.fromJS(filter)).updateIn(['items'], (items: number[]) => immutable.List(items));
    });

    return new Value(value);
  }

  /**
   * 総件数に対する現在のページ数を返す
   *
   * @returns {number}
   */
  public get currentCount(): number {
    return Math.min(this.page * this.perPage, this.totalCount);
  }

  /**
   * 総件数と 1ページ毎の件数から最大ページ数を返す
   *
   * @returns {number}
   */
  public get maxPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  /**
   * 前のページ数を返す
   * 前のページ数がない場合は 0 を返す
   *
   * @returns {number}
   */
  public get prevPage(): number {
    return this.page <= 0 ? 0 : this.page - 1;
  }

  /**
   * 次のページ数を返す
   * 次のページ数がない場合は 0 を返す
   *
   * @returns {number}
   */
  public get nextPage(): number {
    return this.page >= this.maxPage ? 0 : this.page + 1;
  }

  /**
   * 表示用に整形した総件数に対する現在のページ数を返す
   *
   * @returns {string}
   */
  public get formatCurrentCount(): string {
    return Utils.formatSeparate(this.currentCount);
  }

  /**
   * 表示用に整形した総ページ数を返す
   *
   * @returns {string}
   */
  public get formatTotalCount(): string {
    return Utils.formatSeparate(this.totalCount);
  }

  /**
   * API の取得結果をマージする
   *
   * @param {EntityListInterface<number>} entityList
   * @returns {Value}
   */
  public mergeResult(entityList: EntityListInterface<number>): Value {
    return this.withMutations((value: Value) => {
      value.set('items', entityList.result).set('totalCount', entityList.totalCount);
    });
  }
}
