import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Layout from '../../domain/Layout';

/**
 * action
 */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';

export type PAYLOAD_CHANGE_LAYOUT = Layout.Value;

export interface ChangeLayout extends ReduxAction<PAYLOAD_CHANGE_LAYOUT> {}

export type Action = ChangeLayout;

export type Payload = PAYLOAD_CHANGE_LAYOUT;

export const actionCreators = {
  changeLayout: createAction<PAYLOAD_CHANGE_LAYOUT>(CHANGE_LAYOUT),
};

/**
 * reducer
 */
export type State = Layout.Value;

export const defaultState = new Layout.Value();

const init = (state: object) => Layout.Value.fromJS(state);
const replace = (state: State, action: Action) => action.payload as Payload;

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case CHANGE_LAYOUT:
      return replace(newState, action);

    default:
      return newState;
  }
};
