import { actionCreators, Action } from './layout';
import * as Layout from '../../domain/Layout';
/**
 * レイアウトのアクション
 */

export default class LayoutActionDispatcher {
  private dispatch: (action: Action) => Action;

  constructor(dispatch: (action: Action) => Action) {
    this.dispatch = dispatch;

    this.settingLayout = this.settingLayout.bind(this);
  }

  /**
   * ページレイアウトを設定する
   *
   * @param {Layout.LayoutValueData} value
   * @returns {void}
   */
  public settingLayout(value: Layout.LayoutValueData): void {
    const layout = Layout.Value.generate(value);
    this.dispatch(actionCreators.changeLayout(layout));
  }
}
