import { TopicRepositoryInterface } from '../domain/DomainInterface';
import * as Session from '../domain/Session';
import * as Pager from '../domain/Pager';
import * as Topic from '../domain/Topic';
import * as Validation from '../domain/Validation';
import * as User from '../domain/User';

export interface TopicApiInterface {
  confirm(userId: number, value: Topic.Value): Promise<Validation.Value>;
  store(userId: number, value: Topic.Value): Promise<Topic.Entity>;
  fetch(topicId: number): Promise<Topic.Entity>;
  search(topicIds: number[]): Promise<Topic.EntityList>;
  fetchList(pager: Pager.Value, options: Type.HttpTopicListRequestOptions): Promise<Topic.EntityList>;
  disabledPostComment(userId: number, topicId: number): Promise<void>;
}

export interface AccessApiInterface {
  store(userId: number, topicId: number): Promise<void>;
}

export interface ClipApiInterface {
  store(userId: number, topicId: number): Promise<number>;
  search(userId: number, topicId: number): Promise<number>;
  destroy(userId: number, topicId: number): Promise<void>;
}

export interface HeartApiInterface {
  storeTopic(userId: number, topicId: number): Promise<void>;
  searchTopic(userId: number, topicIds: number[]): Promise<number[]>;
}

export interface ViolationApiInterface {
  searchTopic(userId: number, commentIds: number[]): Promise<number[]>;
}

export interface UserApiInterface {
  fetch(userId: number): Promise<User.Entity>;
  fetchList(userIds: number[]): Promise<User.EntityList>;
}

export default class TopicRepository implements TopicRepositoryInterface {
  private session: Session.Value;
  private topicsApi: TopicApiInterface;
  private accessesApi: AccessApiInterface;
  private clipsApi: ClipApiInterface;
  private heartsApi: HeartApiInterface;
  private violationsApi: ViolationApiInterface;
  private usersApi: UserApiInterface;

  public constructor(
    session: Session.Value,
    topicsApi: TopicApiInterface,
    accessesApi: AccessApiInterface,
    clipsApi: ClipApiInterface,
    heartsApi: HeartApiInterface,
    violationsApi: ViolationApiInterface,
    usersApi: UserApiInterface,
  ) {
    this.session = session;
    this.topicsApi = topicsApi;
    this.accessesApi = accessesApi;
    this.clipsApi = clipsApi;
    this.heartsApi = heartsApi;
    this.violationsApi = violationsApi;
    this.usersApi = usersApi;
  }

  /**
   * トピックを 1件保存する
   *
   * @param {Topic.Value} value
   * @returns {Promise<Topic.Entity>}
   */
  public async store(value: Topic.Value): Promise<Topic.Entity> {
    return this.topicsApi.store(this.session.user.identifier, value);
  }

  /**
   * トピックのアクセスを保存する
   *
   * @param {number} topicId
   * @returns {Promise<void>}
   */
  public storeAccess(topicId: number): Promise<void> {
    return this.accessesApi.store(this.session.user.identifier, topicId);
  }

  /**
   * トピックのハート送信を保存する
   *
   * @param {Topic.Entity} entity
   * @returns {Promise<Topic.Entity>}
   */
  public async storeHeart(entity: Topic.Entity): Promise<Topic.Entity> {
    await this.heartsApi.storeTopic(this.session.user.identifier, entity.identifier);

    return entity.sendedHeart();
  }

  /**
   * トピックのクリップを保存する
   *
   * @param {Topic.Entity} entity
   * @returns {Promise<Topic.Entity>}
   */
  public async storeClip(entity: Topic.Entity): Promise<Topic.Entity> {
    const clipId = await this.clipsApi.store(this.session.user.identifier, entity.identifier);

    return entity.set('clipId', clipId);
  }

  /**
   * トピックを 1件取得する
   *
   * @param {number} topicId
   * @returns {Promise<Topic.Entity>}
   */
  public async find(topicId: number): Promise<Topic.Entity> {
    const topicEntityResponse = await this.topicsApi.fetch(topicId);
    if (topicEntityResponse.isEmpty()) {
      return topicEntityResponse;
    }

    const userEntity = await this.usersApi.fetch(topicEntityResponse.value.userId);
    const topicEntity = topicEntityResponse.set('user', userEntity);

    // ゲストの場合はハート送信や違反報告を取得しない
    if (this.session.isGuest()) {
      return topicEntity;
    }

    const [heartResponse, violationResponse, clipResponse] = await Promise.all([
      this.heartsApi.searchTopic(this.session.user.identifier, [topicId]),
      this.violationsApi.searchTopic(this.session.user.identifier, [topicId]),
      this.clipsApi.search(this.session.user.identifier, topicId),
    ]);

    return topicEntity.withMutations((topicEntity: Topic.Entity) => {
      if (heartResponse.indexOf(topicEntity.id) !== -1) {
        topicEntity.set('heart', topicEntity.heart.set('isSendedHeart', true));
      }

      if (violationResponse.indexOf(topicEntity.id) !== -1) {
        topicEntity.set('isSendedViolation', true);
      }

      if (clipResponse !== 0) {
        topicEntity.set('clipId', clipResponse);
      }
    });
  }

  /**
   * トピックリストを取得する
   * カテゴリコードを指定した場合はそのカテゴリのみのリストを取得する
   *
   * @param {Pager.Value} pager
   * @returns {Promise<Topic.EntityList>}
   */
  public findAll(pager: Pager.Value): Promise<Topic.EntityList> {
    const options = {
      category_code: pager.filter.get('categoryCode', 0),
    };

    return this.topicsApi.fetchList(pager, options);
  }

  /**
   * トピックの確認結果を取得する
   * 主にバリデーションの結果を取得する
   *
   * @param {Topic.Value} value
   * @returns {Promise<Validation.Value>}
   */
  public findConfirmResult(value: Topic.Value): Promise<Validation.Value> {
    return this.topicsApi.confirm(this.session.user.identifier, value);
  }

  /**
   * トピックのコメント受付を終了する
   *
   * @param {Topic.Entity} entity
   * @returns {Promise<Topic.Entity>}
   */
  public async updateDisabledPostComment(entity: Topic.Entity): Promise<Topic.Entity> {
    await this.topicsApi.disabledPostComment(this.session.user.identifier, entity.identifier);

    return entity.updateIn(['value'], (value: Topic.Value) => value.set('isCommentable', false));
  }

  /**
   * トピックのクリップを解除する
   *
   * @param {Topic.Entity} entity
   * @returns {Promse<Topic.Entity>}
   */
  public async destroyClip(entity: Topic.Entity): Promise<Topic.Entity> {
    await this.clipsApi.destroy(this.session.user.identifier, entity.clipId);

    return entity.set('clipId', 0);
  }
}
