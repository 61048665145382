import { RequestConfig } from '../libs/axios';
import { ViolationApiInterface as TopicViolationApiInterface } from '../repositories/TopicRepository';
import { ViolationApiInterface as CommentViolationApiInterface } from '../repositories/CommentRepository';
import { ViolationApiInterface } from '../repositories/ViolationRepository';
import * as Violation from '../domain/Violation';
import * as Validation from '../domain/Validation';
import * as Violations from './violations';

export default class ViolationsApi implements TopicViolationApiInterface, CommentViolationApiInterface, ViolationApiInterface {
  private api: Violations.Api;

  public constructor(requestConfig: RequestConfig, target: Type.Target) {
    this.api = Violations.Api(requestConfig, target);
  }

  /**
   * @param {number} userId
   * @param {Violation.Value} value
   * @returns {Promise<Validation.Value>}
   */
  public async confirm(userId: number, value: Violation.Value): Promise<Validation.Value> {
    const request = this.createBody(userId, value);

    return await this.api
      .confirm()
      .execute(request)
      .then(() => new Validation.Value())
      .catch(error => {
        if (!error.status || error.status !== 422) {
          throw error;
        }

        return Promise.resolve(new Validation.Value(error.data));
      });
  }

  /**
   * @param {number} userId
   * @param {Violation.Value} value
   * @returns {Promise<void>}
   */
  public async store(userId: number, value: Violation.Value): Promise<void> {
    const request = this.createBody(userId, value);

    await this.api.store().execute(request);
  }

  /**
   * @param {number} userId
   * @param {Violation.Value} value
   * @returns {Type.HttpViolationPostRequest}
   */
  private createBody(userId: number, value: Violation.Value): Type.HttpViolationPostRequest {
    // トピックの時は topic_id, コメントの時は comment_id を送る
    const targetIdKey = value.target === 'topics' ? 'topic_id' : 'comment_id';

    return {
      user_id: userId,
      [targetIdKey]: value.targetId,
      violation_code: value.code,
      reason: value.reason,
    };
  }

  /**
   * @param {number} userId
   * @param {number[]} topicIds
   * @returns {Promise<number[]>}
   */
  public async searchTopic(userId: number, topicIds: number[]): Promise<number[]> {
    const request = {
      user_id: userId,
      body: {
        topic_ids: topicIds,
      },
    };
    const response = await this.api.search().execute(request);

    return response.list.map(item => item.topic_id) as number[];
  }

  /**
   * @param {number} userId
   * @param {number[]} commentIds
   * @returns {Promise<number[]>}
   */
  public async searchComment(userId: number, commentIds: number[]): Promise<number[]> {
    const request = {
      user_id: userId,
      body: {
        comment_ids: commentIds,
      },
    };
    const response = await this.api.search().execute(request);

    return response.list.map(item => item.comment_id) as number[];
  }
}
