import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpClipListResponse = {
  list: [],
  total_count: 0,
};

export default class FetchListApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpClipListRequest} request
   * @returns {Promise<Type.HttpClipListResponse>}
   */
  public execute(request: Type.HttpClipListRequest): Promise<Type.HttpClipListResponse> {
    const method = 'GET';
    const url = `/v1/users/${request.user_id}/clips/topics?page=${request.page}&per_page=${request.per_page}`;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method).concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }
}
