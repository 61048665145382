import immutable from 'immutable';
import { RequestConfig } from '../libs/axios';
import { UserApiInterface } from '../repositories/UserRepository';
import { UserApiInterface as TopicUserApiInterface } from '../repositories/TopicRepository';
import { UserApiInterface as CommentUserApiInterface } from '../repositories/CommentRepository';
import * as User from '../domain/User';
import * as Users from './users';

export default class UsersApi implements UserApiInterface, TopicUserApiInterface, CommentUserApiInterface {
  private api: Users.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Users.Api(requestConfig);
  }

  /**
   * @param {number} userId
   * @returns {Promise<User.Entity>}
   */
  public async fetch(userId: number): Promise<User.Entity> {
    const request = {
      user_ids: [userId],
    };
    const response = await this.api.fetchList().execute(request);

    if (response.list[0]) {
      return this.convertResponseToEntity(response.list[0]);
    }

    return new User.Entity();
  }

  /**
   * @returns {Promise<User.Entity>}
   */
  public async fetchMy(): Promise<User.Entity> {
    const response = await this.api.fetchMy().execute();

    return this.convertResponseToEntity(response);
  }

  /**
   * @param {number[]} userIds
   * @returns {Promise<User.EntityFetchList>}
   */
  public async fetchList(userIds: number[]): Promise<User.EntityList> {
    const request = {
      user_ids: userIds,
    };

    const response = await this.api.fetchList().execute(request);
    const entities = immutable.Map(
      response.list.map(
        (response: Type.HttpPublicUserResponse): [string, User.Entity] => {
          const entity = this.convertResponseToEntity(response);
          return [entity.identifier.toString(), entity];
        },
      ),
    ) as immutable.Map<string, User.Entity>;

    return new User.EntityList({ entities });
  }

  /**
   * @param {Type.HttpPublicUserResponse | Type.HttpUserResponse} response
   * @returns {User.Entity}
   */
  private convertResponseToEntity(response: Type.HttpPublicUserResponse | Type.HttpUserResponse): User.Entity {
    const picture = 'picture' in response ? response.picture : '';
    const graduationYear = 'school' in response ? response.school.graduation_year : 0;
    let occupationCode = '';
    if ('occupation_code' in response) {
      occupationCode = response.occupation_code;
    } else if ('occupation' in response) {
      occupationCode = response.occupation.occupation_code;
    }

    return new User.Entity({
      sub: response.sub,
      name: response.preferred_username,
      picture,
      graduationYear,
      occupationCode,
      accountStatus: response.account_status,
    });
  }
}
