import { v4 as uuid } from 'uuid';
import { RequestConfig, AxiosHttpClient } from '../libs/axios';
import EnvConstant from '../constants/EnvConstant';
import { PaneApiInterface } from '../repositories/PaneRepository';

type Target = 'header' | 'side' | 'footer';
const state = uuid();
const nonce = uuid();

export default class PaneApi implements PaneApiInterface {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {string} loginSessionToken
   * @param {boolean} isSp
   * @returns {Promise<string>}
   */
  public async fetchHeader(loginSessionToken: string, isSp: boolean): Promise<string> {
    return this.fetch('header', loginSessionToken, isSp);
  }

  /**
   * @param {string} loginSessionToken
   * @param {boolean} isSp
   * @returns {Promise<string>}
   */
  public async fetchSide(loginSessionToken: string, isSp: boolean): Promise<string> {
    return this.fetch('side', loginSessionToken, isSp);
  }

  /**
   * @param {string} loginSessionToken
   * @param {boolean} isSp
   * @returns {Promise<string>}
   */
  public async fetchFooter(loginSessionToken: string, isSp: boolean): Promise<string> {
    return this.fetch('footer', loginSessionToken, isSp);
  }

  /**
   * @param {Target} target
   * @param {string} loginSessionToken
   * @param {boolean} isSp
   * @returns {Promise<string>}
   */
  private async fetch(target: Target, loginSessionToken: string, isSp: boolean): Promise<string> {
    const method = 'POST';
    const url = `/pane/${target}`;
    const body = {
      client_id: EnvConstant.CLIENT_ID,
      redirect_uri: EnvConstant.APP_URL,
      state,
      nonce,
      login_session_token: loginSessionToken,
      sp: isSp,
      ver: '2',
      client_domain: EnvConstant.APP_URL,
    };

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(() => '');
  }
}
