import { RequestConfig } from '../../libs/axios';
import FetchRankingApi from './FetchRankingApi';
import StoreApi from './StoreApi';

export type FetchRankingApi = FetchRankingApi;

export interface Api {
  fetchRanking: () => FetchRankingApi;
  store: () => StoreApi;
}

export const Api = (requestConfig: RequestConfig): Api => ({
  fetchRanking: () => new FetchRankingApi(requestConfig),
  store: () => new StoreApi(requestConfig),
});
