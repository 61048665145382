import { TopRepositoryInterface } from '../domain/DomainInterface';
import * as Pager from '../domain/Pager';
import * as Topic from '../domain/Topic';

export interface TopicApiInterface {
  fetchList(pager: Pager.Value, options: Type.HttpTopicListRequestOptions): Promise<Topic.EntityList>;
  fetchRanking(pager: Pager.Value): Promise<Topic.EntityList>;
}

export default class TopRepository implements TopRepositoryInterface {
  private topicsApi: TopicApiInterface;

  public constructor(topicsApi: TopicApiInterface) {
    this.topicsApi = topicsApi;
  }

  /**
   * ラウンジトップ用のトピックリストを取得する
   * トピック一覧との違いは
   * 新着順、更新順はコメント数が 1000件未満、人気順はランキングテーブルから取得
   *
   * @param {Pager.Value} pager
   * @returns {Promise<Topic.EntityList>}
   */
  public findAllTopic(pager: Pager.Value): Promise<Topic.EntityList> {
    if (pager.filter.get('sort') === 3) {
      return this.topicsApi.fetchRanking(pager);
    }

    const options = {
      comment_count: '*..999',
    };
    return this.topicsApi.fetchList(pager, options);
  }
}
