import immutable from 'immutable';
import Utils from '../libs/utils';
import moment from '../libs/moment';
import { Config } from '../constants/constant';
import { TopicEntityInterface, TopicEntityListInterface } from './DomainInterface';
import * as User from './User';
import * as Heart from './Heart';

type TopicValue = {
  userId: number;
  categoryCode: number;
  title: string;
  content: string;
  commentCount: number;
  accessCount: number;
  isCommentable: boolean;
  enabledProfileLink: boolean;
  isAnonymous: boolean;
  lastUpdatedAt: string;
  createdAt: string;
};

const valueDefaultValue = {
  userId: 0,
  categoryCode: 0,
  title: '',
  content: '',
  commentCount: 0,
  accessCount: 0,
  isCommentable: true,
  enabledProfileLink: true,
  isAnonymous: false,
  lastUpdatedAt: '',
  createdAt: '',
};

const ValueRecord: immutable.Record.Factory<TopicValue> = immutable.Record(valueDefaultValue);
export class Value extends ValueRecord {
  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.categoryCode === 0 && this.title === '' && this.content === '';
  }

  /**
   * 半年前の投稿かどうか判定する
   *
   * @returns {boolean}
   */
  public beforeHalfYearPosted(): boolean {
    return moment().diff(this.createdAt, 'months') >= 6;
  }

  /**
   * 現在日時からのどのくらい前かを返す
   *
   * @param {boolean} isLastUpdatedAt
   * @returns {string}
   */
  public since(isLastUpdatedAt: boolean): string {
    if (isLastUpdatedAt) {
      return moment(this.lastUpdatedAt).fromNow();
    }

    return moment(this.createdAt).fromNow();
  }

  /**
   * 表示用に整形したコメント数を返す
   *
   * @returns {string}
   */
  public get formatCommentCount(): string {
    return Utils.formatSeparate(this.commentCount);
  }

  /**
   * 表示用に整形したアクセス数を返す
   *
   * @returns {string}
   */
  public get formatAccessCount(): string {
    return Utils.formatSeparate(this.accessCount);
  }

  /**
   * 表示用に整形した時刻を返す
   *
   * @returns {string}
   */
  public get formatCreatedAt(): string {
    return Utils.formatDate(this.createdAt, 'YYYY/MM/DD HH:mm');
  }
}

/**
 * トピックエンティティ
 */
type TopicEntity = {
  id: number;
  value: Value;
  heart: Heart.Value;
  isSendedViolation: boolean;
  clipId: number;
  user: User.Entity;
};

const entityDefaultValue = {
  id: 0,
  value: new Value(),
  heart: new Heart.Value(),
  isSendedViolation: false,
  clipId: 0,
  user: new User.Entity(),
};

const EntityRecord: immutable.Record.Factory<TopicEntity> = immutable.Record(entityDefaultValue);
export class Entity extends EntityRecord implements TopicEntityInterface {
  /**
   * オブジェクトからエンティティを生成する
   *
   * @param {object} data
   * @returns {Entity}
   */
  public static fromJS(data: object): Entity {
    const entity = immutable.fromJS(data).withMutations((entity: immutable.Map<string, object>) => {
      return entity
        .updateIn(['value'], (value: object) => new Value(value))
        .updateIn(['heart'], (heart: object) => new Heart.Value(heart))
        .updateIn(['user'], (user: object) => new User.Entity(user));
    });

    return new Entity(entity);
  }

  /**
   * @returns {number}
   */
  public get identifier(): number {
    return this.id;
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.id === 0;
  }

  /**
   * クリップしているか判定する
   *
   * @returns {boolean}
   */
  public isClipped(): boolean {
    return this.clipId !== 0;
  }

  /**
   * ○日以内に投稿されたトピックかどうか判定する
   *
   * @returns {boolean}
   */
  public isNew(): boolean {
    const diff = moment().diff(moment(this.value.createdAt), 'days', true);

    return diff <= Config.NEW_DISPLAY_DAYS;
  }

  /**
   * ○日以内にトピックにコメントが付いて更新されたかどうか判定する
   *
   * @returns {boolean}
   */
  public isUpdated(): boolean {
    const diff = moment().diff(moment(this.value.lastUpdatedAt), 'days', true);

    return diff <= Config.UP_DISPLAY_DAYS;
  }

  /**
   * 投稿者のプロフィールリンクを表示するかどうか
   *
   * @returns {boolean}
   */
  public showProfileLink(): boolean {
    // ユーザ情報が取得できない場合があるので、その場合はリンク非表示
    if (this.user.identifier === 0) {
      return false;
    }

    return this.value.enabledProfileLink;
  }

  /**
   * ハート送信後、コメント数と送信フラグを更新する
   *
   * @returns {Entity}
   */
  public sendedHeart(): Entity {
    const heart = this.heart.withMutations((heart: Heart.Value) => {
      return heart.set('isSendedHeart', true).incrementCount();
    });

    return this.set('heart', heart);
  }

  /**
   * 違反報告後、送信フラグを更新する
   *
   * @returns {Entity}
   */
  public sendedViolation(): Entity {
    return this.set('isSendedViolation', true);
  }
}

/**
 * トピックエンティティリスト
 */
type TopicEntityList = {
  entities: immutable.Map<string, Entity>;
  result: immutable.List<number>;
  totalCount: number;
};

const entityListDefaultValue = {
  entities: immutable.Map<string, Entity>(),
  result: immutable.List<number>(),
  totalCount: 0,
};

const EntityListRecord: immutable.Record.Factory<TopicEntityList> = immutable.Record(entityListDefaultValue);
export class EntityList extends EntityListRecord implements TopicEntityListInterface<number> {
  /**
   * オブジェクトからエンティティを生成する
   *
   * @param {object} data
   * @returns {EntityList}
   */
  public static fromJS(data: object): EntityList {
    const entityList = immutable.fromJS(data).withMutations((entityList: immutable.Map<string, object>) => {
      return entityList.updateIn(['entities'], (entities: immutable.Map<string, object>) => entities.map((entity: object) => Entity.fromJS(entity)));
    });

    return new EntityList(entityList);
  }

  /**
   * @param {EntityList} topicList
   * @returns {EntityList}
   */
  public mergeList(topicList: EntityList): EntityList {
    return this.updateIn(['entities'], (entities: immutable.Map<string, Entity>) =>
      entities.mergeWith((oldVal, newVal) => {
        return oldVal.set('value', newVal.value);
      }, topicList.entities),
    );
  }

  /**
   * @param {Entity} entity
   * @returns {EntityList}
   */
  public replaceEntity(entity: Entity): EntityList {
    return this.updateIn(['entities'], (entities: immutable.Map<string, Entity>) => entities.set(entity.identifier.toString(), entity));
  }

  /**
   * @param {number} topicId
   * @returns {Entity}
   */
  public getEntity(topicId: number): Entity {
    return this.entities.get(topicId.toString(), new Entity());
  }

  /**
   * @param {number[]} topicIds
   * @returns {immutable.List<Entity>}
   */
  public getEntities(topicIds?: number[]): immutable.List<Entity> {
    const topicIdList = topicIds ? immutable.List(topicIds) : this.result;

    return topicIdList.map((topicId: number) => this.getEntity(topicId));
  }

  /**
   * 表示用に整形した総件数を返す
   *
   * @returns {string}
   */
  public get formatTotalCount(): string {
    return Utils.formatSeparate(this.totalCount);
  }
}
