import immutable from 'immutable';
import EnvConstant from '../constants/EnvConstant';
import { LayoutValueData } from './Layout';
import * as Pager from './Pager';

const defaultKeywords = immutable.List(['掲示板', '質問', '相談', '悩み', 'ナース専科', 'ナース', '看護師', '看護学生', 'コミュニティ']);
const DESCRIPTION_MAX_LENGTH = 60;

type SeoValue = {
  meta: {
    title: string;
    description: string;
    keywords: string;
    noindex: boolean;
  };
  link: {
    canonical: string;
    prev: string;
    next: string;
  };
  ogp: {
    title: string;
    type: string;
    siteName: string;
    description: string;
    url: string;
    image: string;
  };
};

const seoDefaultValue = {
  meta: {
    title: '',
    description: '',
    keywords: '',
    noindex: false,
  },
  link: {
    canonical: '',
    prev: '',
    next: '',
  },
  ogp: {
    title: '',
    type: 'article',
    siteName: 'ナース専科',
    description: '',
    url: '',
    image: `${EnvConstant.API_COMMON_PANE_URL}/img/common/logo_nsc.jpg`,
  },
};

const SeoValueRecord: immutable.Record.Factory<SeoValue> = immutable.Record(seoDefaultValue);
export class Value extends SeoValueRecord {
  /**
   * バリューオブジェクトを生成する
   *
   * @param {LayoutValueData['seo']} data
   * @returns {Value}
   */
  public static generate(data?: LayoutValueData['seo']): Value {
    if (!data) {
      return new Value();
    }

    const baseUrl = `${EnvConstant.APP_URL}${data.asPath}`;
    const { page, prevPage, nextPage, filter } = data.pager || new Pager.Value();
    const sort = filter.get('sort');

    const meta = Object.assign(seoDefaultValue.meta, {
      title: data.title,
      description: this.sliceDescription(data.description),
      keywords: this.mergeKeywords(data.addKeywords).join(),
      noindex: data.noindex,
    });

    const link = Object.assign(seoDefaultValue.link, {
      canonical: data.canonical ? `${EnvConstant.APP_URL}${data.canonical}` : '',
      prev: prevPage !== 0 ? `${baseUrl}${this.queryToString({ page: prevPage, sort })}` : '',
      next: nextPage !== 0 ? `${baseUrl}${this.queryToString({ page: nextPage, sort })}` : '',
    });

    const ogp = Object.assign(seoDefaultValue.ogp, {
      title: meta.title,
      description: meta.description,
      url: data.query ? `${baseUrl}${this.queryToString(data.query)}` : `${baseUrl}${page > 0 ? this.queryToString({ page, sort }) : ''}`,
    });

    return new Value({ meta, link, ogp });
  }

  /**
   * description が長すぎる場合は途中で ... と省略する
   *
   * @param {string | undefined} description
   * @returns {string}
   */
  private static sliceDescription(description?: string): string {
    if (!description) {
      return '';
    }

    const replaced = description.replace(/\r?\n/g, '');
    if (replaced.length <= DESCRIPTION_MAX_LENGTH) {
      return replaced;
    }

    return `${replaced.slice(0, DESCRIPTION_MAX_LENGTH)}...`;
  }

  /**
   * keyword をマージする
   *
   * @param {string[] | undefined} addKeywords
   * @returns {immutable.List<string>}
   */
  private static mergeKeywords(addKeywords?: string[]): immutable.List<string> {
    if (!addKeywords || addKeywords.length === 0) {
      return defaultKeywords;
    }

    return defaultKeywords.unshift(...addKeywords);
  }

  /**
   * クエリオブジェクトを文字列に変換する
   *
   * @param {{ [key: string]: any }} query
   * @returns {string}
   */
  private static queryToString(query: { [key: string]: any }): string {
    const queryUrls = immutable.Map(query).map((val: string, key: string) => `${key}=${encodeURIComponent(val)}`);
    if (queryUrls.size === 0) {
      return '';
    }

    return `?${queryUrls.join('&')}`;
  }
}
