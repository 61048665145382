import immutable from 'immutable';
import { CommentRepositoryInterface } from '../domain/DomainInterface';
import * as Session from '../domain/Session';
import * as Pager from '../domain/Pager';
import * as Comment from '../domain/Comment';
import * as Validation from '../domain/Validation';
import * as User from '../domain/User';

export interface CommentApiInterface {
  confirm(userId: number, value: Comment.Value): Promise<Validation.Value>;
  store(userId: number, value: Comment.Value): Promise<Comment.Entity>;
  fetchList(userId: number, topicId: number, pager: Pager.Value): Promise<Comment.EntityList>;
  fetchGuestList(topicId: number, pager: Pager.Value): Promise<Comment.EntityList>;
}

export interface HeartApiInterface {
  storeComment(userId: number, commentId: number): Promise<void>;
  searchComment(userId: number, commentIds: number[]): Promise<number[]>;
}

export interface ViolationApiInterface {
  searchComment(userId: number, commentIds: number[]): Promise<number[]>;
}

export interface UserApiInterface {
  fetchList(userIds: number[]): Promise<User.EntityList>;
}

export default class CommentRepository implements CommentRepositoryInterface {
  private session: Session.Value;
  private commentsApi: CommentApiInterface;
  private heartsApi: HeartApiInterface;
  private violationsApi: ViolationApiInterface;
  private usersApi: UserApiInterface;

  public constructor(
    session: Session.Value,
    commentsApi: CommentApiInterface,
    heartsApi: HeartApiInterface,
    violationsApi: ViolationApiInterface,
    usersApi: UserApiInterface,
  ) {
    this.session = session;
    this.commentsApi = commentsApi;
    this.heartsApi = heartsApi;
    this.violationsApi = violationsApi;
    this.usersApi = usersApi;
  }

  /**
   * コメントを 1件保存する
   *
   * @param {Comment.Value} value
   * @returns {Promise<Comment.Entity>}
   */
  public store(value: Comment.Value): Promise<Comment.Entity> {
    return this.commentsApi.store(this.session.user.identifier, value);
  }

  /**
   * コメントのハート送信を保存する
   *
   * @param {Comment.Entity} entity
   * @returns {Promise<Comment.Entity>}
   */
  public async storeHeart(entity: Comment.Entity): Promise<Comment.Entity> {
    await this.heartsApi.storeComment(this.session.user.identifier, entity.identifier);

    return entity.sendedHeart();
  }

  /**
   * トピックに紐づくコメントのリストを取得する
   *
   * @param {number} topicId
   * @param {Pager.Value} pager
   * @returns {Promise<Comment.EntityList>}
   */
  public async findAll(topicId: number, pager: Pager.Value): Promise<Comment.EntityList> {
    // ゲストの場合はゲスト用のクチコミ一覧を取得する
    if (this.session.isGuest()) {
      return this.findAllGuest(topicId, pager);
    }

    const commentEntityList = await this.commentsApi.fetchList(this.session.user.identifier, topicId, pager);

    // コメントがない場合はハート、違反報告、ユーザ情報を取得しない
    if (commentEntityList.isEmpty()) {
      return commentEntityList;
    }

    const commentIds = commentEntityList.result.toArray();
    const userIds = commentEntityList.userIds.toArray();

    const [heartResponse, violationResponse, userEntityList] = await Promise.all([
      this.heartsApi.searchComment(this.session.user.identifier, commentIds),
      this.violationsApi.searchComment(this.session.user.identifier, commentIds),
      this.usersApi.fetchList(userIds),
    ]);

    const entities = commentEntityList.entities.reduce((entities: immutable.Map<string, Comment.Entity>, entity: Comment.Entity) => {
      const updatedEntity = entity.withMutations((entity: Comment.Entity) => {
        if (heartResponse.indexOf(entity.identifier) !== -1) {
          entity.set('heart', entity.heart.set('isSendedHeart', true));
        }

        if (violationResponse.indexOf(entity.identifier) !== -1) {
          entity.set('isSendedViolation', true);
        }

        entity.set('user', userEntityList.getEntity(entity.value.userId));
      });

      return entities.set(entity.identifier.toString(), updatedEntity);
    }, immutable.Map());

    return commentEntityList.set('entities', entities);
  }

  /**
   * トピックに紐づくコメントのリストを取得する（ゲスト用）
   *
   * @param {number} topicId
   * @param {Pager.Value} pager
   * @returns {Promise<Comment.EntityList>}
   */
  private async findAllGuest(topicId: number, pager: Pager.Value): Promise<Comment.EntityList> {
    const commentEntityList = await this.commentsApi.fetchGuestList(topicId, pager);
    const userIds = commentEntityList.userIds.toArray();
    const userEntityList = await this.usersApi.fetchList(userIds);
    const entities = commentEntityList.entities.reduce((entities: immutable.Map<string, Comment.Entity>, entity: Comment.Entity) => {
      const updatedEntity = entity.set('user', userEntityList.getEntity(entity.value.userId));

      return entities.set(entity.identifier.toString(), updatedEntity);
    }, immutable.Map());

    return commentEntityList.set('entities', entities);
  }

  /**
   * コメントの確認結果を取得する
   * 主にバリデーションの結果を取得する
   *
   * @param {Comment.Value} value
   * @returns {Promise<Validation.Value>}
   */
  public findConfirmResult(value: Comment.Value): Promise<Validation.Value> {
    return this.commentsApi.confirm(this.session.user.identifier, value);
  }
}
