import immutable from 'immutable';
import Utils from '../libs/utils';
import { EntityInterface, MyCommentEntityListInterface } from './DomainInterface';

/**
 * 自分が投稿したコメントエンティティ
 */
type MyCommentEntity = {
  id: number;
  topicId: number;
  content: string;
  categoryCode: number;
  topicTitle: string;
};

const entityDefaultValue = {
  id: 0,
  topicId: 0,
  content: '',
  categoryCode: 0,
  topicTitle: '',
};

const EntityRecord: immutable.Record.Factory<MyCommentEntity> = immutable.Record(entityDefaultValue);
export class Entity extends EntityRecord implements EntityInterface<number> {
  /**
   * @returns {number}
   */
  public get identifier(): number {
    return this.id;
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.id === 0;
  }
}

/**
 * コメントエンティティリスト
 */
type MyCommentEntityList = {
  entities: immutable.Map<string, Entity>;
  result: immutable.List<number>;
  totalCount: number;
};

const entityListDefaultValue = {
  entities: immutable.Map<string, Entity>(),
  result: immutable.List<number>(),
  totalCount: 0,
};

const EntityListRecord: immutable.Record.Factory<MyCommentEntityList> = immutable.Record(entityListDefaultValue);
export class EntityList extends EntityListRecord implements MyCommentEntityListInterface {
  /**
   * オブジェクトからエンティティを生成する
   *
   * @param {object} data
   * @returns {EntityList}
   */
  public static fromJS(data: object): EntityList {
    const entityList = immutable.fromJS(data).withMutations((entityList: immutable.Map<string, object>) => {
      return entityList.updateIn(['entities'], (entities: immutable.Map<string, object>) => entities.map((entity: object) => new Entity(entity)));
    });

    return new EntityList(entityList);
  }

  /**
   * トピックID を抽出して返す
   * 重複したものは排除する
   *
   * @returns {immutable.Set<number>}
   */
  public get topicIds(): immutable.Set<number> {
    return this.entities
      .map((entity: Entity) => entity.topicId)
      .valueSeq()
      .toSet();
  }

  /**
   * エンティティのリストが空かどうかを返す
   *
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.entities.size === 0;
  }

  /**
   * @param {EntityList} categoryList
   * @returns {EntityList}
   */
  public mergeList(commentList: EntityList): EntityList {
    return this.mergeIn(['entities'], commentList.entities).mergeIn(['result'], commentList.result);
  }

  /**
   * @param {Entity} entity
   * @returns {EntityList}
   */
  public replaceEntity(entity: Entity): EntityList {
    return this.updateIn(['entities'], (entities: immutable.Map<string, Entity>) => entities.set(entity.identifier.toString(), entity));
  }

  /**
   * @param {number} commentId
   * @returns {Entity}
   */
  public getEntity(commentId: number): Entity {
    return this.entities.get(commentId.toString(), new Entity());
  }

  /**
   * @param {number[]} commentIds
   * @returns {immutable.List<Entity>}
   */
  public getEntities(commentIds?: number[]): immutable.List<Entity> {
    const commentIdList = commentIds ? immutable.List(commentIds) : this.result;

    return commentIdList.map((commentId: number) => this.getEntity(commentId));
  }

  /**
   * 表示用に整形した総件数を返す
   *
   * @returns {string}
   */
  public get formatTotalCount(): string {
    return Utils.formatSeparate(this.totalCount);
  }
}
