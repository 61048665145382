import { RequestConfig } from '../../libs/axios';
import ConfirmApi from './ConfirmApi';
import SearchApi from './SearchApi';
import StoreApi from './StoreApi';

export interface Api {
  confirm: () => ConfirmApi;
  search: () => SearchApi;
  store: () => StoreApi;
}

export const Api = (requestConfig: RequestConfig, target: Type.Target): Api => ({
  confirm: () => new ConfirmApi(requestConfig, target),
  search: () => new SearchApi(requestConfig, target),
  store: () => new StoreApi(requestConfig, target),
});
