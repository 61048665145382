import immutable from 'immutable';
import { MyRepositoryInterface, MyListResponse } from '../domain/DomainInterface';
import * as Session from '../domain/Session';
import * as Pager from '../domain/Pager';
import * as Topic from '../domain/Topic';
import * as MyComment from '../domain/MyComment';

export interface ClipApiInterface {
  fetchList(userId: number, pager: Pager.Value): Promise<Type.HttpClipListResponse>;
}

export interface TopicApiInterface {
  fetchMyList(userId: number, pager: Pager.Value): Promise<Topic.EntityList>;
  search(topicIds: number[]): Promise<Topic.EntityList>;
}

export interface CommentApiInterface {
  fetchMyList(userId: number, pager: Pager.Value): Promise<MyComment.EntityList>;
}

export default class MyRepository implements MyRepositoryInterface {
  private session: Session.Value;
  private clipsApi: ClipApiInterface;
  private topicsApi: TopicApiInterface;
  private commentsApi: CommentApiInterface;

  public constructor(session: Session.Value, clipsApi: ClipApiInterface, topicsApi: TopicApiInterface, commentsApi: CommentApiInterface) {
    this.session = session;
    this.clipsApi = clipsApi;
    this.topicsApi = topicsApi;
    this.commentsApi = commentsApi;
  }

  /**
   * Myページに必要な一覧をすべて取得する
   *
   * @returns {Promise<MyListResponse>}
   */
  public async find(pager: Pager.Value): Promise<MyListResponse> {
    const [clips, topics, comments] = await Promise.all([this.findClippedTopicList(pager), this.findAllMyTopic(pager), this.findAllMyComment(pager)]);

    return {
      clips,
      topics,
      comments,
    };
  }

  /**
   * クリップしたトピック一覧を取得する
   *
   * @param {Pager.Value} pager
   * @returns {Promise<Topic.EntityList>}
   */
  public async findClippedTopicList(pager: Pager.Value): Promise<Topic.EntityList> {
    const clipListResponse = await this.clipsApi.fetchList(this.session.user.identifier, pager);
    const topicIds = clipListResponse.list.map(item => item.topic_id);
    const topicEntityList = await this.topicsApi.search(topicIds);

    return topicEntityList.set('totalCount', clipListResponse.total_count);
  }

  /**
   * 自分が投稿したトピックリストを取得する
   *
   * @param {Pager.Value} pager
   * @returns {Promise<Topic.EntityList>}
   */
  public findAllMyTopic(pager: Pager.Value): Promise<Topic.EntityList> {
    return this.topicsApi.fetchMyList(this.session.user.identifier, pager);
  }

  /**
   * 自分が投稿したコメントリストを取得する
   *
   * @param {Pager.Value} pager
   * @returns {Promise<MyComment.EntityList>}
   */
  public async findAllMyComment(pager: Pager.Value): Promise<MyComment.EntityList> {
    const myCommentEntityList = await this.commentsApi.fetchMyList(this.session.user.identifier, pager);
    const topicEntityList = await this.topicsApi.search(myCommentEntityList.topicIds.toArray());

    return myCommentEntityList.updateIn(['entities'], (entities: immutable.Map<string, MyComment.Entity>) =>
      entities.map((myCommentEntity: MyComment.Entity) => {
        const topicEntity = topicEntityList.getEntity(myCommentEntity.topicId);
        return myCommentEntity.withMutations((entity: MyComment.Entity) => {
          return entity.set('topicTitle', topicEntity.value.title).set('categoryCode', topicEntity.value.categoryCode);
        });
      }),
    );
  }
}
