import { RequestConfig } from '../../libs/axios';
import FetchListApi from './FetchListApi';

export interface Api {
  fetchList: () => FetchListApi;
}

export const Api = (requestConfig: RequestConfig): Api => ({
  fetchList: () => new FetchListApi(requestConfig),
});
