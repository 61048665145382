import { RequestConfig } from '../../libs/axios';
import IssueGuestTokenApi from './IssueGuestTokenApi';
import VerifyAccessTokenApi from './VerifyAccessTokenApi';
import VerifyLoginSessionTokenApi from './VerifyLoginSessionTokenApi';

export interface Api {
  issueGuestToken: () => IssueGuestTokenApi;
  verifyAccessToken: () => VerifyAccessTokenApi;
  verifyLoginSessionToken: () => VerifyLoginSessionTokenApi;
}

export const Api = (requestConfig: RequestConfig): Api => {
  return {
    issueGuestToken: () => new IssueGuestTokenApi(requestConfig),
    verifyAccessToken: () => new VerifyAccessTokenApi(requestConfig),
    verifyLoginSessionToken: () => new VerifyLoginSessionTokenApi(requestConfig),
  };
};
