import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpTopicResponse = {
  id: 0,
  user_id: 0,
  category_code: 0,
  title: '',
  content: '',
  comment_count: 0,
  heart_count: 0,
  access_count: 0,
  is_commentable: true,
  enabled_profile_link: true,
  is_anonymous: false,
  created_at: '',
  last_updated_at: '',
};

export default class FetchApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpTopicRequest} request
   * @returns {Promise<Type.HttpTopicResponse>}
   */
  public execute(request: Type.HttpTopicRequest): Promise<Type.HttpTopicResponse> {
    const method = 'GET';
    const url = `/v1/topics/active/${request.topic_id}`;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method).concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }
}
