import immutable from 'immutable';

/**
 * 設定
 */
export const Config = {
  /** new 表示の期間 */
  NEW_DISPLAY_DAYS: 1,
  /** up 表示の期間 */
  UP_DISPLAY_DAYS: 1,
  /** SP のページャー数 */
  PAGER_NODE_NUM_SP: 3,
  /** PC のページャー数 */
  PAGER_NODE_NUM_PC: 5,
  /** トピック一覧のソート文字列と index の対応表 */
  TOPIC_SORT_MAP: immutable.Map<number, string>().withMutations((map: immutable.Map<number, string>) => {
    return map
      .set(1, '-last_updated_at')
      .set(2, '-created_at')
      .set(3, '-access_count');
  }),
  /** コメント一覧のソート文字列と index の対応表 */
  COMMENT_SORT_MAP: immutable.Map<number, string>().withMutations((map: immutable.Map<number, string>) => {
    return map.set(1, '-created_at').set(2, 'created_at');
  }),
};

/**
 * クッキーの設定
 */
export const CookieConstant = {
  KEY_ACCOUNT_LOGIN: 'login_session_token',
  OPTION: {
    httpOnly: false,
    secure: true,
    maxAge: 60 * 60 * 24 * 1000 * 14,
  },
};

/**
 * 違反報告
 */
export type ViolationCode = {
  code: number;
  name: string;
};
export const ViolationConstant = {
  CODES: immutable.Map<number, ViolationCode>().withMutations((map: immutable.Map<number, ViolationCode>) => {
    return map
      .set(0, { code: 0, name: '違反区分' })
      .set(1, { code: 1, name: '個人情報の掲載' })
      .set(2, { code: 2, name: '広告的な内容' })
      .set(3, { code: 3, name: '重複投稿、マルチポスト' })
      .set(4, { code: 4, name: '著作権の侵害' })
      .set(5, { code: 5, name: '無関係なリンク' })
      .set(6, { code: 6, name: '意味のわからない内容' })
      .set(7, { code: 7, name: 'アダルト的な内容' })
      .set(8, { code: 8, name: '不要なhtmlタグ、スクリプトなどが含まれている' })
      .set(9, { code: 9, name: '誹謗中傷' })
      .set(10, { code: 10, name: 'その他' });
  }),
};

/**
 * 絵文字入力欄
 */
export const PictographConstant = {
  ITEMS: [
    '😃',
    '😊',
    '😆',
    '😲',
    '😢',
    '😭',
    '😠',
    '😡',
    '😵',
    '😱',
    '😏',
    '😒',
    '😳',
    '😍',
    '😰',
    '😷',
    '🙇',
    '👍',
    '✌',
    '✨',
    '⭐',
    '🎵',
    '♥',
    '💔',
    '🔥',
    '💢',
    '💦',
    '‼',
    '❓',
    '⁉',
    '⤴',
    '⤵',
    '💤',
    '☕',
    '🌼',
    '💮',
    '✏',
    '💪',
    '🏃',
    '💨',
  ],
};
