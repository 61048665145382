import { CategoryRepositoryInterface } from '../domain/DomainInterface';
import * as Category from '../domain/Category';

export interface CategoryApiInterface {
  fetchList(): Promise<Category.EntityList>;
}

export default class CategoryRepository implements CategoryRepositoryInterface {
  private api: CategoryApiInterface;

  public constructor(api: CategoryApiInterface) {
    this.api = api;
  }

  /**
   * カテゴリを全件取得する
   *
   * @returns {Promise<Category.EntityList>}
   */
  public async findAll(): Promise<Category.EntityList> {
    return this.api.fetchList();
  }
}
