import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

export default class ConfirmApi {
  private requestConfig: RequestConfig;
  private target: Type.Target;

  public constructor(requestConfig: RequestConfig, target: Type.Target) {
    this.requestConfig = requestConfig;
    this.target = target;
  }

  /**
   * @param {Type.HttpViolationPostRequest} request
   * @returns {Promise<Type.HttpViolationResponse>}
   */
  public async execute(request: Type.HttpViolationPostRequest): Promise<Type.HttpViolationResponse> {
    const method = 'POST';
    const url = `/v1/users/${request.user_id}/violations/${this.target}/confirm`;
    const body = request;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .concatUrl(url);
    });

    const response = await AxiosHttpClient.instance.execute(requestConfig);

    return response.data;
  }
}
