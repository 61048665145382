import immutable from 'immutable';
import moment from '../libs/moment';
import EnvConstant from '../constants/EnvConstant';
import { UserEntityInterface, EntityListInterface } from './DomainInterface';

/**
 * ユーザエンティティ
 */
type EntityType = {
  sub: number;
  name: string;
  picture: string;
  occupationCode: string;
  graduationYear: number;
  accountStatus: string;
};

const entityDefaultValue = {
  sub: 0,
  name: '',
  picture: '',
  occupationCode: '',
  graduationYear: 0,
  accountStatus: '',
};

const UserRecord: immutable.Record.Factory<EntityType> = immutable.Record(entityDefaultValue);
export class Entity extends UserRecord implements UserEntityInterface {
  /**
   * @returns {number}
   */
  public get identifier(): number {
    return this.sub;
  }

  /**
   * 卒業年から経験年数を算出し、文字列で返す
   *
   * @returns {'expert' | 'mid_career' | 'newcomer' | 'student' | 'guest'}
   */
  public get experience(): 'expert' | 'mid_career' | 'newcomer' | 'student' | 'guest' {
    if (this.graduationYear === 0 || this.isGuest()) {
      return 'guest';
    }

    return this.graduationYearToExperience(this.graduationYear);
  }

  /**
   * @param {number} graduationYear
   * @returns {'expert' | 'mid_career' | 'newcomer' | 'student' | 'guest'}
   */
  private graduationYearToExperience(graduationYear: number): 'expert' | 'mid_career' | 'newcomer' | 'student' | 'guest' {
    const experience = this.calcExperienceYear(graduationYear);

    if (experience < 1) {
      return 'student';
    }

    if (experience >= 1 && experience < 2) {
      return 'newcomer';
    }

    if (experience >= 2 && experience < 10) {
      return 'mid_career';
    }

    if (experience >= 10) {
      return 'expert';
    }

    return 'guest';
  }

  /**
   * @param {number} graduationYear
   * @returns {number}
   */
  private calcExperienceYear(graduationYear: number): number {
    const fromDate = moment(graduationYear, 'YYYY');
    const toDate = moment();

    return toDate.diff(fromDate, 'years');
  }

  /**
   * 職業コードから学生か既卒かを文字列で返す
   *
   * @returns {string}
   */
  public get occupation(): 'student' | 'nurse' | string {
    // ログインしていない場合はどちらでもない
    if (this.isGuest()) {
      return 'guest';
    }

    if (this.occupationCode === 'student' || this.occupationCode === 'student_with_licenses') {
      return 'student';
    }

    return 'nurse';
  }

  /**
   * 表示用のニックネームを返す
   *
   * @returns {string}
   */
  public get displayName(): string {
    if (this.name === '') {
      return '未設定';
    }

    return this.name;
  }

  /**
   * 表示用のプロフィール画像 url を返す
   *
   * @returns {string}
   */
  public get displayPicture(): string {
    if (this.picture === '') {
      return `${EnvConstant.CDN_PROFILE_PICTURE_URL}/default/unknown.jpg`;
    }

    return this.picture;
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.sub === 0;
  }

  /**
   * エンティティが等しいかどうかを判定する
   *
   * @param {Entity} user
   * @returns {boolean}
   */
  public isEqual(user: Entity): boolean {
    return this.identifier === user.identifier;
  }

  /**
   * ゲストかどうかを返す
   *
   * @returns {boolean}
   */
  public isGuest(): boolean {
    return this.isEmpty();
  }

  /**
   * ニックネームが設定されていないかどうかを判定する
   *
   * @returns {boolean}
   */
  public isEmptyName(): boolean {
    return this.name === '';
  }

  /**
   * 退会済みかどうかを返す
   *
   * @returns {boolean}
   */
  public isCanceled(): boolean {
    return this.accountStatus === 'canceled' || this.accountStatus === 'banned';
  }
}

/**
 * ユーザエンティティリスト
 */
type EntityListType = {
  entities: immutable.Map<string, Entity>;
};

const entityListDefaultValue = {
  current: 0,
  entities: immutable.Map<string, Entity>(),
};

const UserListRecord: immutable.Record.Factory<EntityListType> = immutable.Record(entityListDefaultValue);
export class EntityList extends UserListRecord implements EntityListInterface<number> {
  /**
   * @returns {immutable.List<number>}
   */
  public get result(): immutable.List<number> {
    return this.entities
      .keySeq()
      .map((sub: string) => parseInt(sub, 10))
      .toList();
  }

  /**
   * @returns {number}
   */
  public get totalCount(): number {
    return this.entities.size;
  }

  /**
   * @param {EntityList} categoryList
   * @returns {EntityList}
   */
  public mergeList(commentList: EntityList): EntityList {
    return this.mergeIn(['entities'], commentList.entities);
  }

  /**
   * @param {Entity} entity
   * @returns {EntityList}
   */
  public replaceEntity(entity: Entity): EntityList {
    return this.updateIn(['entities'], (entities: immutable.Map<string, Entity>) => entities.set(entity.identifier.toString(), entity));
  }

  /**
   * @param {number} userId
   * @returns {Entity}
   */
  public getEntity(userId: number): Entity {
    return this.entities.get(userId.toString(), new Entity());
  }

  /**
   * @param {number[]} userIds
   * @returns {immutable.List<Entity>}
   */
  public getEntities(userIds: number[]): immutable.List<Entity> {
    const userIdList = immutable.List(userIds);

    return userIdList.map((userId: number) => this.getEntity(userId));
  }
}
