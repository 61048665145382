import { RequestConfig } from '../../libs/axios';
import SearchApi from './SearchApi';
import StoreApi from './StoreApi';

export interface Api {
  store: () => StoreApi;
  search: () => SearchApi;
}

export const Api = (requestConfig: RequestConfig, target: Type.Target): Api => ({
  store: () => new StoreApi(requestConfig, target),
  search: () => new SearchApi(requestConfig, target),
});
