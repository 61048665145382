import { RequestConfig } from '../libs/axios';
import { HeartApiInterface as TopicHeartApiInterface } from '../repositories/TopicRepository';
import { HeartApiInterface as CommentHeartApiInterface } from '../repositories/CommentRepository';
import * as Hearts from './hearts';

export default class HeartsApi implements TopicHeartApiInterface, CommentHeartApiInterface {
  private api: Hearts.Api;

  public constructor(requestConfig: RequestConfig, target: Type.Target) {
    this.api = Hearts.Api(requestConfig, target);
  }

  /**
   * @param {number} userId
   * @param {number} topicId
   * @returns {Promise<void>}
   */
  public async storeTopic(userId: number, topicId: number): Promise<void> {
    const request = {
      user_id: userId,
      topic_id: topicId,
    };

    await this.api
      .store()
      .execute(request)
      .catch(() => {});
  }

  /**
   * @param {number} userId
   * @param {number} commentId
   * @returns {Promise<void>}
   */
  public async storeComment(userId: number, commentId: number): Promise<void> {
    const request = {
      user_id: userId,
      comment_id: commentId,
    };

    await this.api
      .store()
      .execute(request)
      .catch(() => {});
  }

  /**
   * @param {number} userId
   * @param {number[]} topicIds
   * @returns {Promise<number[]>}
   */
  public async searchTopic(userId: number, topicIds: number[]): Promise<number[]> {
    const request = {
      user_id: userId,
      body: {
        topic_ids: topicIds,
      },
    };
    const response = await this.api.search().execute(request);

    return response.list.map(item => item.topic_id) as number[];
  }

  /**
   * @param {number} userId
   * @param {number[]} commentIds
   * @returns {Promise<number[]>}
   */
  public async searchComment(userId: number, commentIds: number[]): Promise<number[]> {
    const request = {
      user_id: userId,
      body: {
        comment_ids: commentIds,
      },
    };
    const response = await this.api.search().execute(request);

    return response.list.map(item => item.comment_id) as number[];
  }
}
