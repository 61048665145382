import immutable from 'immutable';
import { Value as BreadcrumbValue } from './Breadcrumb';
import { Value as SeoValue } from './Seo';
import * as Pager from './Pager';

export type LayoutValueData = {
  breadcrumb?: {
    data: {
      path: string;
      label: string;
    }[];
    params?: {
      categoryCode?: number;
      topicId?: number;
    };
  };
  header?: {
    show?: boolean;
    showTitle?: boolean;
    isTop?: boolean;
    pageTitle?: string;
    isNewHeader?: boolean;
  };
  footer?: {
    show?: boolean;
    showTopLink?: boolean;
    showPostButton?: boolean;
  };
  seo?: {
    asPath: string;
    pager?: Pager.Value;
    query?: Record<string, string | string[] | undefined>;
    title?: string;
    description?: string;
    addKeywords?: string[];
    noindex?: boolean;
    canonical?: string;
  };
};

/**
 * ヘッダーのバリューオブジェクト
 */
type HeaderValueType = {
  show: boolean; // header の表示/非表示
  showTitle: boolean; // header のタイトルの表示/非表示
  isTop: boolean; // ラウンジトップかどうか
  pageTitle: string; // ページのタイトル
  isNewHeader?: boolean; // 新しいヘッダーを活用するかどうか
};

const headerDefaultValue = {
  show: true,
  showTitle: true,
  isTop: false,
  pageTitle: '',
  isNewHeader: false,
};

const HeaderValueRecord: immutable.Record.Factory<HeaderValueType> = immutable.Record(headerDefaultValue);
class HeaderValue extends HeaderValueRecord {}

/**
 * フッターのバリューオブジェクト
 */
type FooterValueType = {
  show: boolean; // footer の表示/非表示
  showTopLink: boolean; // footer 下部のラウンジトップのリンクの表示/非表示
  showPostButton: boolean; // トピック投稿ボタンの表示/非表示
};

const footerDefaultValue = {
  show: true,
  showTopLink: true,
  showPostButton: false,
};

const FooterValueRecord: immutable.Record.Factory<FooterValueType> = immutable.Record(footerDefaultValue);
class FooterValue extends FooterValueRecord {}

/**
 * レイアウトのバリューオブジェクト
 */
type LayoutValue = {
  breadcrumb: BreadcrumbValue;
  header: HeaderValue;
  footer: FooterValue;
  seo: SeoValue;
};

const layoutDefaultValue = {
  breadcrumb: new BreadcrumbValue(),
  header: new HeaderValue(),
  footer: new FooterValue(),
  seo: new SeoValue(),
};

const LayoutValueRecord: immutable.Record.Factory<LayoutValue> = immutable.Record(layoutDefaultValue);
export class Value extends LayoutValueRecord {
  /**
   * オブジェクトからバリューオブジェクトを生成する
   *
   * @param {object} data
   * @returns {Value}
   */
  public static fromJS(data: object): Value {
    const value = immutable.fromJS(data).withMutations((value: immutable.Map<string, object>) => {
      return value
        .updateIn(['breadcrumb'], (breadcrumb: immutable.Map<string, object>) => new BreadcrumbValue(breadcrumb.toJS()))
        .updateIn(['header'], (header: object) => new HeaderValue(header))
        .updateIn(['footer'], (footer: object) => new FooterValue(footer))
        .updateIn(['seo'], (seo: immutable.Map<string, object>) => new SeoValue(seo.toJS()));
    });

    return new Value(value);
  }

  /**
   * バリューオブジェクトを生成する
   *
   * @param {LayoutValueData} data
   * @returns {Value}
   */
  public static generate(data: LayoutValueData): Value {
    const breadcrumb = new BreadcrumbValue(data.breadcrumb as any);
    const header = new HeaderValue(data.header);
    const footer = new FooterValue(data.footer);
    const seo = SeoValue.generate(data.seo);

    return new Value({ breadcrumb, header, footer, seo });
  }
}
