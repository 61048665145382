import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpHeartListResponse = {
  list: [],
};

export default class SearchApi {
  private requestConfig: RequestConfig;
  private target: Type.Target;

  public constructor(requestConfig: RequestConfig, target: Type.Target) {
    this.requestConfig = requestConfig;
    this.target = target;
  }

  /**
   * @param {Type.HttpHeartSearchRequest} request
   * @returns {Promise<Type.HttpHeartListResponse>}
   */
  public execute(request: Type.HttpHeartSearchRequest): Promise<Type.HttpHeartListResponse> {
    const method = 'POST';
    const url = `/v1/users/${request.user_id}/hearts/${this.target}/search`;
    const body = request.body;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }
}
