import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpTopicListResponse = {
  list: [],
};

export default class SearchApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpTopicSearchRequest} request
   * @returns {Promise<Type.HttpTopicListResponse>}
   */
  public execute(request: Type.HttpTopicSearchRequest): Promise<Type.HttpTopicListResponse> {
    const method = 'POST';
    const url = '/v1/topics/search';
    const body = request;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }
}
