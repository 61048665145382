import React from 'react';
import { NextContext } from 'next';
import Router from '../libs/router';
import Utils from '../libs/utils';

/**
 * 認証済みでないと表示させない場合に挟むコンポーネント
 */
interface Props {
  isAuthorized: boolean;
}

const WrappedComponent = (ComposedComponent: any) => {
  class AuthComponent extends React.Component<Props> {
    public static async getInitialProps(ctx: NextContext) {
      const { store, pathname, res } = ctx;
      const isGuest = store.getState().commons.session.isGuest();
      if (isGuest) {
        return Router.redirect(Utils.createAuhorizeUrl(pathname), res);
      }

      const pageProps = await ComposedComponent.getInitialProps(ctx);

      return {
        isAuthorized: true,
        ...pageProps,
      };
    }

    public shouldComponentUpdate(): boolean {
      return !this.props.isAuthorized;
    }

    public render(): React.ReactNode {
      if (!this.props.isAuthorized) {
        return null;
      }
      return <ComposedComponent {...this.props} />;
    }
  }

  return AuthComponent;
};

export default WrappedComponent;
