import { UserRepositoryInterface } from '../domain/DomainInterface';
import * as User from '../domain/User';

export interface UserApiInterface {
  fetchMy(): Promise<User.Entity>;
}

export default class UserRepository implements UserRepositoryInterface {
  private api: UserApiInterface;

  public constructor(api: UserApiInterface) {
    this.api = api;
  }

  /**
   * 自分のユーザ情報を 1件取得する
   *
   * @returns {Promise<User.Entity>}
   */
  public async find(): Promise<User.Entity> {
    return this.api.fetchMy();
  }
}
