/**
 * 環境変数
 */
export default class EnvConstant {
  /**
   * @param {string | undefined} value
   * @returns {number}
   */
  protected static toNumber(value: string | undefined): number {
    return value ? parseInt(value, 10) : 0;
  }

  /**
   * @param {string | undefined} value
   * @returns {string}
   */
  protected static toString(value: string | undefined): string {
    return value ? value : '';
  }

  /**
   * @returns {string}
   */
  public static get APP_ENV(): string {
    return this.toString(process.env.APP_ENV);
  }

  /**
   * @returns {string}
   */
  public static get APP_URL(): string {
    return this.toString(process.env.APP_URL);
  }

  /**
   * @returns {string}
   */
  public static get API_URL(): string {
    return this.toString(process.env.API_URL);
  }

  /**
   * @returns {string}
   */
  public static get SORRY_SERVER_URI(): string {
    return this.toString(process.env.SORRY_SERVER_URI);
  }

  /**
   * @returns {string}
   */
  public static get SITEMAP_URI(): string {
    return `${this.toString(process.env.SITEMAP_URI)}/lounge`;
  }

  /**
   * @returns {string}
   */
  public static get AUTHORIZATION_FORM_URI(): string {
    return `${this.ACCOUNT_WEB_URL}/auth/authorize`;
  }

  /**
   * @returns {string}
   */
  public static get API_GATEWAY_URL(): string {
    return this.toString(process.env.API_GATEWAY_URL);
  }

  /**
   * @returns {string}
   */
  public static get API_COMMON_PANE_URL(): string {
    return this.toString(process.env.API_COMMON_PANE_URL);
  }

  /**
   * @returns {string}
   */
  public static get CDN_ASSETS_URL(): string {
    return this.toString(process.env.CDN_ASSETS_URL);
  }

  /**
   * @returns {string}
   */
  public static get CDN_PROFILE_PICTURE_URL(): string {
    return this.toString(process.env.CDN_PROFILE_PICTURE_URL);
  }

  /**
   * @returns {string}
   */
  public static get PORTAL_WEB_URL(): string {
    return this.toString(process.env.PORTAL_WEB_URL);
  }

  /**
   * @returns {string}
   */
  public static get ACCOUNT_API_URL(): string {
    return this.toString(process.env.ACCOUNT_API_URL);
  }

  /**
   * @returns {string}
   */
  public static get ACCOUNT_WEB_URL(): string {
    return this.toString(process.env.ACCOUNT_WEB_URL);
  }

  /**
   * @returns {number}
   */
  public static get CLIENT_ID(): number {
    return this.toNumber(process.env.CLIENT_ID);
  }

  /**
   * @returns {string}
   */
  public static get SENTRY_DSN(): string {
    return this.toString(process.env.SENTRY_DSN);
  }

  /**
   * @returns {string}
   */
  public static get WORKPLACE_REPUTATION_WEB_URL(): string {
    return this.toString(process.env.WORKPLACE_REPUTATION_WEB_URL);
  }
}
