import { RequestConfig, AxiosHttpClient } from '../../libs/axios';
import EnvConstant from '../../constants/EnvConstant';

const responseDefaultValue: Type.HttpVerifyLoginSessionTokenResponse = {
  login_session_token: '',
};

export default class VerifyLoginSessionTokenApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpVerifyLoginSessionTokenRequest} request
   * @returns {Promise<Type.HttpVerifyLoginSessionTokenResponse>}
   */
  public execute(request: Type.HttpVerifyLoginSessionTokenRequest): Promise<Type.HttpVerifyLoginSessionTokenResponse> {
    const method = 'POST';
    const url = `${EnvConstant.ACCOUNT_WEB_URL}/login-sessions`;
    const body = request;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .set('url', url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(() => responseDefaultValue);
  }
}
