import immutable from 'immutable';
import EnvConstant from '../constants/EnvConstant';
import { RequestConfig } from '../libs/axios';
import * as Session from '../domain/Session';
import PaneApi from '../api/PaneApi';
import PaneRepository from '../repositories/PaneRepository';
import AuthApi from '../api/AuthApi';
import AuthRepository from '../repositories/AuthRepository';
import CategoriesApi from '../api/CategoriesApi';
import CategoryRepository from '../repositories/CategoryRepository';
import TopicsApi from '../api/TopicsApi';
import TopicRepository from '../repositories/TopicRepository';
import CommentsApi from '../api/CommentsApi';
import CommentRepository from '../repositories/CommentRepository';
import ViolationsApi from '../api/ViolationsApi';
import ViolationRepository from '../repositories/ViolationRepository';
import UsersApi from '../api/UsersApi';
import UserRepository from '../repositories/UserRepository';
import TopRepository from '../repositories/TopRepository';
import MyRepository from '../repositories/MyRepository';
import AccessesApi from '../api/AccessesApi';
import HeartsApi from '../api/HeartsApi';
import ClipsApi from '../api/ClipsApi';

export default class RepositoryFactory {
  /**
   * @returns {PaneRepository}
   */
  public static createPaneRepository(): PaneRepository {
    const headers = immutable.Map({ 'Content-Type': 'text/html' });
    const url = EnvConstant.API_COMMON_PANE_URL;
    const requestConfig = new RequestConfig({ headers, url });
    const paneApi = new PaneApi(requestConfig);

    return new PaneRepository(paneApi);
  }

  /**
   * @returns {AuthRepository}
   */
  public static createAuthRepository(): AuthRepository {
    const headers = immutable.Map({ 'Content-Type': 'application/json' });
    const requestConfig = new RequestConfig({ headers });
    const api = new AuthApi(requestConfig);

    return new AuthRepository(api);
  }

  /**
   * @param {Session.Value} session
   * @returns {TopRepository}
   */
  public static createTopRepository(session: Session.Value): TopRepository {
    const headers = this.createHeaders(session);
    const url = this.createLoungeBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });
    const topicsApi = new TopicsApi(requestConfig);

    return new TopRepository(topicsApi);
  }

  /**
   * @param {Session.Value} session
   * @returns {CategoryRepository}
   */
  public static createCategoryRepository(session: Session.Value): CategoryRepository {
    const headers = this.createHeaders(session);
    const url = this.createLoungeBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });
    const api = new CategoriesApi(requestConfig);

    return new CategoryRepository(api);
  }

  /**
   * @param {Session.Value} session
   * @returns {TopicRepository}
   */
  public static createTopicRepository(session: Session.Value): TopicRepository {
    const headers = this.createHeaders(session);
    const url = this.createLoungeBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });
    const topicsApi = new TopicsApi(requestConfig);
    const accessesApi = new AccessesApi(requestConfig);
    const clipsApi = new ClipsApi(requestConfig);
    const heartsApi = new HeartsApi(requestConfig, 'topics');
    const violationsApi = new ViolationsApi(requestConfig, 'topics');

    return new TopicRepository(session, topicsApi, accessesApi, clipsApi, heartsApi, violationsApi, this.createUsersApi(headers));
  }

  /**
   * @param {Session.Value} session
   * @returns {CommentRepository}
   */
  public static createCommentRepository(session: Session.Value): CommentRepository {
    const headers = this.createHeaders(session);
    const url = this.createLoungeBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });
    const commentsApi = new CommentsApi(requestConfig);
    const heartsApi = new HeartsApi(requestConfig, 'comments');
    const violationsApi = new ViolationsApi(requestConfig, 'comments');

    return new CommentRepository(session, commentsApi, heartsApi, violationsApi, this.createUsersApi(headers));
  }

  /**
   * @param {Session.Value} session
   * @param {Type.Target} target
   * @returns {CommentRepository}
   */
  public static createViolationRepository(session: Session.Value, target: Type.Target): ViolationRepository {
    const headers = this.createHeaders(session);
    const url = this.createLoungeBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });
    const violationsApi = new ViolationsApi(requestConfig, target);

    return new ViolationRepository(session, violationsApi);
  }

  /**
   * @param {Session.Value} session
   * @returns {MyRepository}
   */
  public static createMyRepository(session: Session.Value): MyRepository {
    const headers = this.createHeaders(session);
    const url = this.createLoungeBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });
    const clipsApi = new ClipsApi(requestConfig);
    const topicsApi = new TopicsApi(requestConfig);
    const commentsApi = new CommentsApi(requestConfig);

    return new MyRepository(session, clipsApi, topicsApi, commentsApi);
  }

  /**
   * @param {Session.Value} session
   * @returns {UserRepository}
   */
  public static createUserRepository(session: Session.Value): UserRepository {
    const headers = this.createHeaders(session);
    const url = this.createUserInfoBaseUrl(session);
    const requestConfig = new RequestConfig({ headers, url });
    const usersApi = new UsersApi(requestConfig);

    return new UserRepository(usersApi);
  }

  /**
   * ヘッダーを生成する
   *
   * @returns {immutable.Map<string, string>}
   */
  private static createHeaders(session: Session.Value): immutable.Map<string, string> {
    const env = EnvConstant.APP_ENV;
    if (env === 'local' || env === 'testing') {
      return immutable.Map({
        'Content-Type': 'application/json',
      });
    }

    return immutable.Map({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session.accessToken}`,
    });
  }

  /**
   * @returns {string}
   */
  private static get createLoungeBaseUrl(): string {
    const env = EnvConstant.APP_ENV;
    if (env === 'local' || env === 'testing') {
      return EnvConstant.API_URL;
    }

    return `${EnvConstant.API_GATEWAY_URL}/lounge`;
  }

  /**
   * @returns {string}
   */
  private static get createAccountBaseUrl(): string {
    const env = EnvConstant.APP_ENV;
    if (env === 'local' || env === 'testing') {
      return EnvConstant.ACCOUNT_API_URL;
    }

    return `${EnvConstant.API_GATEWAY_URL}/account`;
  }

  /**
   * @param {Session.Value} session
   * @returns {string}
   */
  private static createUserInfoBaseUrl(session: Session.Value): string {
    const env = EnvConstant.APP_ENV;
    if (env === 'local' || env === 'testing') {
      const sub = session.decodeLoginSessionToken.sub;
      return `${EnvConstant.ACCOUNT_API_URL}/v1/users/${sub}`;
    }

    return `${EnvConstant.API_GATEWAY_URL}/userinfo`;
  }

  /**
   * @param {immutable.Map<string, string>} headers
   * @returns {UsersApi}
   */
  private static createUsersApi(headers: immutable.Map<string, string>): UsersApi {
    const url = this.createAccountBaseUrl;
    const requestConfig = new RequestConfig({ headers, url });

    return new UsersApi(requestConfig);
  }
}
