import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpCategoryListResponse = {
  list: [],
};

export default class FetchListApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @returns {Promise<Type.HttpCategoryListResponse>}
   */
  public execute(): Promise<Type.HttpCategoryListResponse> {
    const method = 'GET';
    const url = '/v1/categories';

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method).concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }
}
