import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

export default class DisabledPostCommentApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpDisabledPostCommentRequest} request
   * @returns {Promise<Type.HttpTopicResponse>}
   */
  public async execute(request: Type.HttpDisabledPostCommentRequest): Promise<Type.HttpTopicResponse> {
    const method = 'PATCH';
    const url = `/v1/users/${request.user_id}/topics/${request.topic_id}/disabled_post_comment`;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method).concatUrl(url);
    });

    const response = await AxiosHttpClient.instance.execute(requestConfig);

    return response.data;
  }
}
