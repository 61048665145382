import NextRouter, { EventChangeOptions } from 'next/router';
import { ServerResponse } from 'http';
import { UrlObject, Url } from 'url';

type UrlLike = UrlObject | Url;

export default class Router {
  /**
   * 画面遷移（スクロールしない）
   *
   * @param {string | UrlLike} url
   * @param {string | UrlLike} as
   * @param {EventChangeOptions} options
   * @returns {Promise<boolean>}
   */
  public static push(url: string | UrlLike, as?: string | UrlLike, options?: EventChangeOptions): Promise<boolean> {
    return NextRouter.push(url, as, options);
  }

  /**
   * 画面遷移後ページトップにスクロールさせる
   *
   * @param {string | UrlLike} url
   * @param {string | UrlLike} as
   * @param {EventChangeOptions} options
   * @returns {Promise<boolean>}
   */
  public static pushScrollTo(url: string | UrlLike, as?: string | UrlLike, hash?: string, options?: EventChangeOptions): Promise<boolean> {
    return NextRouter.push(url, as, options).then(() => {
      this.scrollToHash(hash);
      return true;
    });
  }

  /**
   * 指定 id までスクロールさせる
   *
   * @param {string} hash
   * @returns {void}
   */
  public static scrollToHash(hash?: string): void {
    // 指定がない場合はトップ
    if (!hash || hash === '') {
      return window.scrollTo(0, 0);
    }

    // id 指定の場合
    const idElement = document.getElementById(hash);
    if (idElement) {
      return idElement.scrollIntoView();
    }

    // name 指定の場合
    const nameElement = document.getElementsByName(hash)[0];
    if (nameElement) {
      return nameElement.scrollIntoView();
    }
  }

  /**
   * ユニバーサルリダイレクト
   *
   * @param {string} pathname
   * @param {ServerResponse | undefined} res
   * @param {boolean} isOutside
   * @returns {void | string}
   */
  public static redirect(pathname: string, res?: ServerResponse): void | string {
    if (res) {
      res.writeHead(301, { Location: pathname });
      return res.end();
    }

    // http, https で始まる場合は外部サイトとみなしてリダイレクト
    if (pathname.indexOf('http') !== -1) {
      return (location.href = pathname);
    }

    this.pushScrollTo(pathname);
  }
}
