import immutable from 'immutable';

type ValidationValue = {
  code: number;
  message: string;
  errors: { [key: string]: string[] };
};

const defaultValue = {
  code: 422,
  message: '',
  errors: {},
};

const ValueRecord: immutable.Record.Factory<ValidationValue> = immutable.Record(defaultValue);
export class Value extends ValueRecord {
  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.code === defaultValue.code && this.message === '';
  }

  /**
   * エラーが有るかどうかを返す
   *
   * @param {string} name
   * @returns {boolean}
   */
  public hasError(name: string): boolean {
    return this.errors[name] !== undefined;
  }

  /**
   * エラーメッセージを返す
   *
   * @param {string} name
   * @returns {string}
   */
  public errorMessage(name: string): string {
    if (this.hasError(name)) {
      return this.errors[name][0];
    }

    return '';
  }
}
