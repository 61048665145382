import immutable from 'immutable';
import { RequestConfig } from '../libs/axios';
import { CategoryApiInterface } from '../repositories/CategoryRepository';
import * as Category from '../domain/Category';
import * as Categories from './categories';

export default class CategoriesApi implements CategoryApiInterface {
  private api: Categories.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Categories.Api(requestConfig);
  }

  /**
   * @returns {Promise<Category.EntityList>}
   */
  public async fetchList(): Promise<Category.EntityList> {
    const response = await this.api.fetchList().execute();

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {Type.HttpCategoryListResponse} response
   * @returns {Category.EntityList}
   */
  private convertResponseToEntityList(response: Type.HttpCategoryListResponse): Category.EntityList {
    const entityList = response.list.reduce(
      (entityList: { entities: { [key: number]: Category.Entity }; result: number[] }, response) => {
        entityList.entities[response.code] = new Category.Entity({
          code: response.code,
          name: response.name,
        });
        entityList.result.push(response.code);

        return entityList;
      },
      { entities: {}, result: [] },
    );

    return new Category.EntityList({
      entities: immutable.Map(entityList.entities),
      result: immutable.List(entityList.result),
    });
  }
}
