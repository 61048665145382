import { RequestConfig } from '../libs/axios';
import * as Auth from './auth';

export default class AuthApi {
  private api: Auth.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Auth.Api(requestConfig);
  }

  /**
   * @param {number} clientId
   * @returns {Promise<string>}
   */
  public async issueGuestToken(clientId: number): Promise<string> {
    const request = {
      client_id: clientId,
    };

    const response = await this.api.issueGuestToken().execute(request);

    return response.access_token;
  }

  /**
   * @param {string} accessToken
   * @returns {Promise<boolean>}
   */
  public async verifyAccessToken(accessToken: string): Promise<boolean> {
    const request = {
      access_token: accessToken,
    };

    const response = await this.api.verifyAccessToken().execute(request);

    return response.is_valid;
  }

  /**
   * @param {string} loginSessionToken
   * @returns {Promise<string>}
   */
  public async verifyLoginSessionToken(loginSessionToken: string): Promise<string> {
    const request = {
      login_session_token: loginSessionToken,
    };

    const response = await this.api.verifyLoginSessionToken().execute(request);

    return response.login_session_token;
  }
}
