import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

export default class StoreApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpClipPostRequest} request
   * @returns {Promise<Type.HttpClipResponse>}
   */
  public async execute(request: Type.HttpClipPostRequest): Promise<Type.HttpClipResponse> {
    const method = 'POST';
    const url = `/v1/users/${request.user_id}/clips/topics`;
    const body = request;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config
        .set('method', method)
        .set('body', body)
        .concatUrl(url);
    });

    const response = await AxiosHttpClient.instance.execute(requestConfig);

    return response.data;
  }
}
