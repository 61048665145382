import { RequestConfig } from '../libs/axios';
import { AccessApiInterface } from '../repositories/TopicRepository';
import * as Accesses from './accesses';

export default class AccessesApi implements AccessApiInterface {
  private api: Accesses.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Accesses.Api(requestConfig);
  }

  /**
   * @param {number} userId
   * @param {number} topicId
   * @returns {Promise<void>}
   */
  public async store(userId: number, topicId: number): Promise<void> {
    const request = {
      access_user_id: userId,
      topic_id: topicId,
    };

    await this.api.store().execute(request);
  }
}
