import { RequestConfig, AxiosHttpClient } from '../../libs/axios';
import EnvConstant from '../../constants/EnvConstant';

const responseDefaultValue: Type.HttpVerifyAccessTokenResponse = {
  is_valid: false,
  access_token: '',
  client_id: 0,
  scopes: [],
  expires_at: 0,
};

export default class VerifyAccessTokenApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpVerifyAccessTokenRequest} request
   * @returns {Promise<Type.HttpVerifyAccessTokenResponse>}
   */
  public execute(request: Type.HttpVerifyAccessTokenRequest): Promise<Type.HttpVerifyAccessTokenResponse> {
    const method = 'GET';
    const url = `${EnvConstant.API_GATEWAY_URL}/tokens/access-token?${request.access_token}`;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method).set('url', url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(() => responseDefaultValue);
  }
}
