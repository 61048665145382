import { RequestConfig, AxiosHttpClient } from '../../libs/axios';

const responseDefaultValue: Type.HttpTopicListResponse = {
  list: [],
  total_count: 0,
};

export default class FetchListApi {
  private requestConfig: RequestConfig;

  public constructor(requestConfig: RequestConfig) {
    this.requestConfig = requestConfig;
  }

  /**
   * @param {Type.HttpTopicListRequest} request
   * @returns {Promise<Type.HttpTopicListResponse>}
   */
  public execute(request: Type.HttpTopicListRequest): Promise<Type.HttpTopicListResponse> {
    const method = 'GET';
    const url = `/v1/topics/active${this.queryParamsUrl(request)}`;

    const requestConfig = this.requestConfig.withMutations((config: RequestConfig) => {
      return config.set('method', method).concatUrl(url);
    });

    return AxiosHttpClient.instance
      .execute(requestConfig)
      .then(response => response.data)
      .catch(error => {
        // 認証に失敗した時はエラーを返す、それ以外は空レスポンスを返す
        if (error.status === 401) {
          throw error;
        }

        return responseDefaultValue;
      });
  }

  /**
   * @param {Pager.Value} pager
   * @param {number} categoryCode
   * @returns {string}
   */
  private queryParamsUrl(request: Type.HttpTopicListRequest): string {
    let url = '?';
    if (request.options.category_code && request.options.category_code !== 0) {
      url = `${url}category_code=${request.options.category_code}&`;
    }

    if (request.options.comment_count && request.options.comment_count !== '') {
      url = `${url}comment_count=${request.options.comment_count}&`;
    }

    return `${url}sort=${request.sort}&page=${request.page}&per_page=${request.per_page}`;
  }
}
