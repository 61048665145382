import { SentryLogger } from '@bananakun/sentry-frontend-logger';
import { v4 as uuid } from 'uuid';
import EnvConstant from '../constants/EnvConstant';

export default class Logger {
  private static traceId = uuid();

  /**
   * sentryのインスタンス生成
   *
   * @returns {SentryLogger}
   */
  private static instance(): SentryLogger {
    const config = {
      traceId: this.traceId,
      serviceName: 'lounge-web',
      environment: EnvConstant.APP_ENV,
    };

    return new SentryLogger(EnvConstant.SENTRY_DSN, config);
  }

  /**
   * sentryへエラーログを出力する
   *
   * @param {Error} error
   */
  public static error(error: Error): void {
    const appEnv = EnvConstant.APP_ENV;
    if (appEnv === 'local' || appEnv === 'testing') {
      console.log(error);
      return;
    }

    this.instance().error(error);
  }
}
