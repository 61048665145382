import immutable from 'immutable';
import Utils from '../libs/utils';

type HeartValue = {
  count: number;
  isSendedHeart: boolean;
};

const defaultValue = {
  count: 0,
  isSendedHeart: false,
};

const ValueRecord: immutable.Record.Factory<HeartValue> = immutable.Record(defaultValue);
export class Value extends ValueRecord {
  /**
   * 表示用に整形したハート数を返す
   *
   * @returns {string}
   */
  public get formatCount(): string {
    return Utils.formatSeparate(this.count);
  }

  /**
   * ハート数を +1 する
   *
   * @returns {Value}
   */
  public incrementCount(): Value {
    return this.set('count', this.count + 1);
  }
}
