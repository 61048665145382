import { RequestConfig } from '../../libs/axios';
import DestroyApi from './DestroyApi';
import FetchListApi from './FetchListApi';
import SearchApi from './SearchApi';
import StoreApi from './StoreApi';

export interface Api {
  store: () => StoreApi;
  fetchList: () => FetchListApi;
  search: () => SearchApi;
  destroy: () => DestroyApi;
}

export const Api = (requestConfig: RequestConfig): Api => ({
  store: () => new StoreApi(requestConfig),
  fetchList: () => new FetchListApi(requestConfig),
  search: () => new SearchApi(requestConfig),
  destroy: () => new DestroyApi(requestConfig),
});
