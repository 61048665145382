import { RequestConfig } from '../../libs/axios';
import ConfirmApi from './ConfirmApi';
import FetchGuestListApi from './FetchGuestListApi';
import FetchListApi from './FetchListApi';
import FetchMyListApi from './FetchMyListApi';
import StoreApi from './StoreApi';

export interface Api {
  confirm: () => ConfirmApi;
  store: () => StoreApi;
  fetchList: () => FetchListApi;
  fetchGuestList: () => FetchGuestListApi;
  fetchMyList: () => FetchMyListApi;
}

export const Api = (requestConfig: RequestConfig): Api => {
  return {
    confirm: () => new ConfirmApi(requestConfig),
    store: () => new StoreApi(requestConfig),
    fetchList: () => new FetchListApi(requestConfig),
    fetchGuestList: () => new FetchGuestListApi(requestConfig),
    fetchMyList: () => new FetchMyListApi(requestConfig),
  };
};
