import immutable from 'immutable';
import { decode, DecodeOptions } from 'jsonwebtoken';
import { v4 as uuid } from 'uuid';
import EnvConstant from '../constants/EnvConstant';
import moment from '../libs/moment';

export default class Utils {
  /**
   * ユーザエージェントからスマホかどうかを判定する
   *
   * @param {string | string[]} ua
   * @returns {boolean}
   */
  public static isSp(ua?: string | string[]): boolean {
    if (!ua) {
      return false;
    }

    if (
      ua.indexOf('iPhone') > 0 ||
      ua.indexOf('iPod') > 0 ||
      (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) ||
      ua.indexOf('Windows Phone') > 0
    ) {
      return true;
    }

    return false;
  }

  /**
   * 数値に変換する
   *
   * @param {any} value
   * @returns {number}
   */
  public static parseInt(value: any): number {
    const parsed = parseInt(value, 10);
    if (isNaN(parsed)) {
      return 0;
    }

    return parsed;
  }

  /**
   * フォーマットが指定された場合はそのフォーマットに変換して返す
   * フォーマットが指定されなかった場合は以下の条件で変換して返す
   * 同じ年の場合は 月日時分 に変換
   * 年が違う場合は 年月日 に変換
   *
   * @param {string} strDate
   * @param {string|undefined} format
   * @returns {string}
   */
  public static formatDate(strDate: string, format?: string): string {
    const date = moment(strDate);
    if (format) {
      return date.format(format);
    }

    if (date.year() === moment().year()) {
      return date.format('MM月DD日HH時mm分');
    }

    return date.format('YYYY年MM月DD日');
  }

  /**
   * 数値をカンマ区切りにする
   *
   * @param {number} num
   * @returns {string}
   */
  public static formatSeparate(num: number): string {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  /**
   * JWTトークンをデコードする
   *
   * @param {string} token
   * @param {DecodeOptions} options
   * @returns {object | string}
   */
  public static decodeJwtToken<T extends object | string>(token: string, options?: DecodeOptions): T {
    return decode(token, options) as T;
  }

  /**
   * 認可サーバへのリダイレクト url を生成して返す
   *
   * @param {string} redirectPath
   * @returns {string}
   */
  public static createAuhorizeUrl(redirectPath: string = '/'): string {
    const queryParams = {
      client_id: EnvConstant.CLIENT_ID,
      redirect_uri: `${EnvConstant.APP_URL}${redirectPath}`,
      state: uuid(),
      nonce: uuid(),
    };
    const queryUrls = immutable.Map(queryParams).map((val: string, key: string) => `${key}=${encodeURIComponent(val)}`);

    return `${EnvConstant.AUTHORIZATION_FORM_URI}/?${queryUrls.join('&')}`;
  }

  /**
   * ユーザエージェントから Google Bot か判定する
   *
   * @param {string | string[]} ua
   * @returns {boolean}
   */
  public static isGoogleBot(ua?: string | string[]): boolean {
    if (!ua) {
      return false;
    }

    if (ua.indexOf('Googlebot') > 0) {
      return true;
    }

    return false;
  }
}
