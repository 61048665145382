import immutable from 'immutable';
import { Config } from '../constants/constant';
import { RequestConfig } from '../libs/axios';
import { TopicApiInterface } from '../repositories/TopicRepository';
import { TopicApiInterface as TopTopicApiInterface } from '../repositories/TopRepository';
import { TopicApiInterface as MyTopicApiInterface } from '../repositories/MyRepository';
import * as Pager from '../domain/Pager';
import * as Topic from '../domain/Topic';
import * as Heart from '../domain/Heart';
import * as Validation from '../domain/Validation';
import * as Topics from './topics';

export default class TopicsApi implements TopicApiInterface, TopTopicApiInterface, MyTopicApiInterface {
  private api: Topics.Api;

  public constructor(requestConfig: RequestConfig) {
    this.api = Topics.Api(requestConfig);
  }

  /**
   * @param {number} userId
   * @param {Topic.Value} value
   * @returns {Promise<Validation.Value>}
   */
  public async confirm(userId: number, value: Topic.Value): Promise<Validation.Value> {
    const request = {
      user_id: userId,
      category_code: value.categoryCode,
      title: value.title,
      content: value.content,
      enabled_profile_link: value.enabledProfileLink,
    };

    return await this.api
      .confirm()
      .execute(request)
      .then(() => new Validation.Value())
      .catch(error => {
        if (!error.status || error.status !== 422) {
          throw error;
        }

        return Promise.resolve(new Validation.Value(error.data));
      });
  }

  /**
   * @param {number} userId
   * @param {Topic.Value} value
   * @returns {Promise<Topic.Entity>}
   */
  public async store(userId: number, value: Topic.Value): Promise<Topic.Entity> {
    const request = {
      user_id: userId,
      category_code: value.categoryCode,
      title: value.title,
      content: value.content,
      enabled_profile_link: value.enabledProfileLink,
    };

    const response = await this.api.store().execute(request);

    return this.convertResponseToEntity(response);
  }

  /**
   * @param {FetchRequest} request
   * @returns {Promise<Topic.Entity>}
   */
  public async fetch(topicId: number): Promise<Topic.Entity> {
    const request = {
      topic_id: topicId,
    };
    const response = await this.api.fetch().execute(request);

    return this.convertResponseToEntity(response);
  }

  /**
   * @param {number[]} topicIds
   * @returns {Promise<Topic.EntityFetchList>}
   */
  public async search(topicIds: number[]): Promise<Topic.EntityList> {
    const request = {
      topic_ids: topicIds,
    };
    const response = await this.api.search().execute(request);

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {Pager.Value} pager
   * @param {Type.HttpTopicListRequestOptions} options
   * @returns {Promise<Topic.EntityFetchList>}
   */
  public async fetchList(pager: Pager.Value, options: Type.HttpTopicListRequestOptions): Promise<Topic.EntityList> {
    const request = {
      options,
      sort: Config.TOPIC_SORT_MAP.get(pager.filter.get('sort'), '-last_updated_at'),
      page: pager.page,
      per_page: pager.perPage,
    };
    const response = await this.api.fetchList().execute(request);

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {number} userId
   * @param {Pager.Value} pager
   * @returns {Promise<Topic.EntityFetchList>}
   */
  public async fetchMyList(userId: number, pager: Pager.Value): Promise<Topic.EntityList> {
    const request = {
      user_id: userId,
      sort: Config.TOPIC_SORT_MAP.get(pager.filter.get('sort'), '-created_at'),
      page: pager.page,
      per_page: pager.perPage,
    };
    const response = await this.api.fetchMyList().execute(request);

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {Pager.Value} pager
   * @returns {Promise<Topic.EntityFetchList>}
   */
  public async fetchRanking(pager: Pager.Value): Promise<Topic.EntityList> {
    const request = {
      page: pager.page,
      per_page: pager.perPage,
    };
    const response = await this.api.fetchRanking().execute(request);

    return this.convertResponseToEntityList(response);
  }

  /**
   * @param {number} userId
   * @param {number} topicId
   * @returns {Promise<void>}
   */
  public async disabledPostComment(userId: number, topicId: number): Promise<void> {
    const request = {
      user_id: userId,
      topic_id: topicId,
    };

    await this.api
      .disabledPostComment()
      .execute(request)
      .catch(() => {});
  }

  /**
   * @param {Type.HttpTopicResponse} response
   * @returns {Topic.Entity}
   */
  private convertResponseToEntity(response: Type.HttpTopicResponse): Topic.Entity {
    const topicValue = new Topic.Value({
      userId: response.user_id,
      categoryCode: response.category_code,
      title: response.title,
      content: response.content,
      commentCount: response.comment_count,
      accessCount: response.access_count,
      isCommentable: response.is_commentable,
      enabledProfileLink: response.enabled_profile_link,
      isAnonymous: response.is_anonymous,
      createdAt: response.created_at,
      lastUpdatedAt: response.last_updated_at,
    });

    const heartValue = new Heart.Value({
      count: response.heart_count,
    });

    const topic = {
      id: response.id,
      value: topicValue,
      heart: heartValue,
    };

    return new Topic.Entity(topic);
  }

  /**
   * @param {Type.HttpTopicListResponse} response
   * @returns {Topic.EntityList}
   */
  private convertResponseToEntityList(response: Type.HttpTopicListResponse): Topic.EntityList {
    const entityList = response.list.reduce(
      (entityList: { entities: { [key: number]: Topic.Entity }; result: number[] }, response: Type.HttpTopicResponse) => {
        entityList.entities[response.id] = this.convertResponseToEntity(response);
        entityList.result.push(response.id);

        return entityList;
      },
      { entities: {}, result: [] },
    );

    return new Topic.EntityList({
      entities: immutable.Map(entityList.entities),
      result: immutable.List(entityList.result),
      totalCount: response.total_count,
    });
  }
}
