import immutable from 'immutable';
import EnvConstant from '../constants/EnvConstant';

type BreadcrumbList = Breadcrumb[];
type Breadcrumb = {
  name: string;
  href: string;
  as: string;
};

/**
 * パンくずリストのバリューオブジェクト
 */
type BreadcrumbValueType = {
  data: BreadcrumbData[];
  params: {
    categoryCode: number;
    topicId: number;
  };
};

type BreadcrumbData = {
  path: string; // トップ > ラウンジ 以降に表示させるパンくずのパス - パスと url を紐付ける
  label: string; // パンくずの表示文言
};

const breadcrumbDefaultValue = {
  data: [],
  params: {
    categoryCode: 0,
    topicId: 0,
  },
};

const BreadcrumbValueRecord: immutable.Record.Factory<BreadcrumbValueType> = immutable.Record(breadcrumbDefaultValue);
export class Value extends BreadcrumbValueRecord {
  /**
   * @returns {BreadcrumbList}
   */
  public list(): BreadcrumbList {
    // 1つ目は必ずトップ
    const list = [
      { name: 'トップ', href: EnvConstant.PORTAL_WEB_URL, as: EnvConstant.PORTAL_WEB_URL },
      { name: 'しゃべる', href: EnvConstant.APP_URL, as: EnvConstant.APP_URL },
    ];

    return list.concat(
      this.data.map((data, index) => {
        return { name: data.label, href: this.href(data), as: EnvConstant.APP_URL + this.asPath(data) };
      }),
    );
  }

  /**
   * ルーティング用の url
   *
   * @param {BreadcrumbData} data
   * @returns {string}
   */
  private href(data: BreadcrumbData): string {
    switch (data.path) {
      case 'category':
        return `/topic/list?category_code=${this.params.categoryCode}`;
      case 'detail':
        return `/topic/detail?topic_id=${this.params.topicId}`;
      case 'my':
        return '/my';
      case 'my_clip':
        return '/my/clip_list';
      case 'my_topic':
        return '/my/topic_list';
      case 'my_comment':
        return '/my/comment_list';
      default:
        return '';
    }
  }

  /**
   * 表示用の url
   *
   * @param {BreadcrumbData} data
   * @returns {string}
   */
  private asPath(data: BreadcrumbData): string {
    switch (data.path) {
      case 'category':
        return this.params.categoryCode === 0 ? '/topic/list' : `/topic/list/${this.params.categoryCode}`;
      case 'detail':
        return `/topic/detail/${this.params.topicId}`;
      case 'my':
        return '/my';
      case 'my_clip':
        return '/my/clip_list';
      case 'my_topic':
        return '/my/topic_list';
      case 'my_comment':
        return '/my/comment_list';
      default:
        return '';
    }
  }
}
