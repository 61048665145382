import immutable from 'immutable';
import { CategoryEntityInterface, CategoryEntityListInterface } from './DomainInterface';

const CLASS_NAMES = immutable.Map<number, string>().withMutations((map: immutable.Map<number, string>) => {
  return map
    .set(1, 'career') // キャリア・転職
    .set(2, 'care') // 看護・ケア
    .set(3, 'smile') // フリートーク
    .set(4, 'pen') // 国家試験
    .set(5, 'beginner') // 新人ナース
    .set(10, 'bag') // 就職
    .set(14, 'stethoscope') // 実習
    .set(18, 'pay') // 給与・待遇
    .set(19, 'hospital') // 職場
    .set(20, 'books'); // 学校生活
});

export const RECOMMEND_LIST = immutable.Map<string, number[]>().withMutations((map: immutable.Map<string, number[]>) => {
  return map
    .set('expert', [3, 2, 1, 19, 18, 5])
    .set('mid_career', [3, 2, 1, 19, 18, 5])
    .set('newcomer', [5, 2, 3, 1, 19, 18])
    .set('student', [4, 14, 2, 10, 3, 20])
    .set('guest', [2, 3, 19, 18, 1, 5]);
});

export const TOPIC_FORM_LIST = immutable.Map<string, number[]>().withMutations((map: immutable.Map<string, number[]>) => {
  return map.set('student', [2, 4, 3, 10, 20, 14]).set('nurse', [2, 19, 1, 3, 18, 5]);
});

/**
 * カテゴリエンティティ
 */
type EntityType = {
  code: number;
  name: string;
};

const entityDefaultValue = {
  code: 0,
  name: '',
};

const CategoryRecord: immutable.Record.Factory<EntityType> = immutable.Record(entityDefaultValue);
export class Entity extends CategoryRecord implements CategoryEntityInterface {
  /**
   * @returns {number}
   */
  public get identifier(): number {
    return this.code;
  }

  /**
   * css のクラス名を返す
   *
   * @returns {string}
   */
  public get className(): string {
    return CLASS_NAMES.get(this.code, '');
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.code === 0;
  }
}

/**
 * カテゴリエンティティリスト
 */
type EntityListType = {
  entities: immutable.Map<string, Entity>;
  result: immutable.List<number>;
};

const entityListDefaultValue = {
  entities: immutable.Map<string, Entity>(),
  result: immutable.List<number>(),
};

const CategoryListRecord: immutable.Record.Factory<EntityListType> = immutable.Record(entityListDefaultValue);
export class EntityList extends CategoryListRecord implements CategoryEntityListInterface {
  /**
   * オブジェクトからバリューオブジェクトを生成する
   *
   * @param {object} data
   * @returns {EntityList}
   */
  public static fromJS(data: object): EntityList {
    const entityList = immutable.fromJS(data).withMutations((entityList: immutable.Map<string, object>) => {
      return entityList.updateIn(['entities'], (entities: immutable.Map<string, object>) => entities.map((entity: object) => new Entity(entity)));
    });

    return new EntityList(entityList);
  }

  /**
   * @returns {number}
   */
  public get totalCount(): number {
    return this.entities.size;
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.entities.size === 0;
  }

  /**
   * @param {EntityList} categoryList
   * @returns {EntityList}
   */
  public mergeList(categoryList: EntityList): EntityList {
    return this.mergeIn(['entities'], categoryList.entities).mergeIn(['result'], categoryList.result);
  }

  /**
   * @param {number} code
   * @returns {Entity}
   */
  public getEntity(code: number): Entity {
    return this.entities.get(code.toString(), new Entity());
  }

  /**
   * @param {number[]} codes
   * @returns {immutable.List<Entity>}
   */
  public getEntities(codes?: number[]): immutable.List<Entity> {
    const codeList = codes ? immutable.List(codes) : this.result;

    return codeList.map((code: number) => this.getEntity(code));
  }

  /**
   * 経験文字列からおすすめのカテゴリを返す
   *
   * @param {'expert' | 'mid_career' | 'newcomer' | 'student' | 'guest'} experience
   * @returns {immutable.List<Entity>}
   */
  public recommendedEntities(experience: 'expert' | 'mid_career' | 'newcomer' | 'student' | 'guest'): immutable.List<Entity> {
    return this.getEntities(RECOMMEND_LIST.get(experience));
  }

  /**
   * 職業文字列からトピック投稿のカテゴリを返す
   *
   * @param {'student' | 'nurse' | string} occupation
   * @returns {immutable.List<Entity>}
   */
  public topicFormEntities(occupation: 'student' | 'nurse' | string): immutable.List<Entity> {
    return this.getEntities(TOPIC_FORM_LIST.get(occupation));
  }
}
