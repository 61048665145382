import immutable from 'immutable';
import Utils from '../libs/utils';
import { CommentEntityInterface, CommentEntityListInterface } from './DomainInterface';
import * as User from './User';
import * as Heart from './Heart';

/**
 * コメントバリューオブジェクト
 */
type CommentValue = {
  topicId: number;
  userId: number;
  sequenceNo: number;
  content: string;
  isAnonymous: boolean;
  createdAt: string;
};

const valueDefaultValue = {
  topicId: 0,
  userId: 0,
  sequenceNo: 0,
  content: '',
  isAnonymous: false,
  createdAt: '',
};

const ValueRecord: immutable.Record.Factory<CommentValue> = immutable.Record(valueDefaultValue);
export class Value extends ValueRecord {
  /**
   * 表示用に整形した時刻を返す
   *
   * @returns {string}
   */
  public get formatCreatedAt(): string {
    return Utils.formatDate(this.createdAt, 'YYYY/MM/DD HH:mm:ss');
  }
}

/**
 * コメントエンティティ
 */
type CommentEntity = {
  id: number;
  value: Value;
  heart: Heart.Value;
  isSendedViolation: boolean;
  user: User.Entity;
};

const entityDefaultValue = {
  id: 0,
  value: new Value(),
  heart: new Heart.Value(),
  isSendedViolation: false,
  user: new User.Entity(),
};

const EntityRecord: immutable.Record.Factory<CommentEntity> = immutable.Record(entityDefaultValue);
export class Entity extends EntityRecord implements CommentEntityInterface {
  /**
   * オブジェクトからエンティティを生成する
   *
   * @param {object} data
   * @returns {Entity}
   */
  public static fromJS(data: object): Entity {
    const entity = immutable.fromJS(data).withMutations((entity: immutable.Map<string, object>) => {
      return entity
        .updateIn(['value'], (value: object) => new Value(value))
        .updateIn(['heart'], (heart: object) => new Heart.Value(heart))
        .updateIn(['user'], (user: object) => new User.Entity(user));
    });

    return new Entity(entity);
  }

  /**
   * @returns {number}
   */
  public get identifier(): number {
    return this.id;
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.id === 0;
  }

  /**
   * 投稿者のプロフィールリンクを表示するかどうか
   *
   * @returns {boolean}
   */
  public showProfileLink(): boolean {
    // ユーザ情報が取得できない場合があるので、その場合はリンク非表示
    return this.user.identifier !== 0;
  }

  /**
   * ハート送信後、コメント数と送信フラグを更新する
   *
   * @returns {Entity}
   */
  public sendedHeart(): Entity {
    const heart = this.heart.withMutations((heart: Heart.Value) => {
      return heart.set('isSendedHeart', true).incrementCount();
    });

    return this.set('heart', heart);
  }

  /**
   * 違反報告後、送信フラグを更新する
   *
   * @returns {Entity}
   */
  public sendedViolation(): Entity {
    return this.set('isSendedViolation', true);
  }
}

/**
 * コメントエンティティリスト
 */
type CommentEntityList = {
  entities: immutable.Map<string, Entity>;
  result: immutable.List<number>;
  totalCount: number;
};

const entityListDefaultValue = {
  entities: immutable.Map<string, Entity>(),
  result: immutable.List<number>(),
  totalCount: 0,
};

const EntityListRecord: immutable.Record.Factory<CommentEntityList> = immutable.Record(entityListDefaultValue);
export class EntityList extends EntityListRecord implements CommentEntityListInterface {
  /**
   * オブジェクトからエンティティを生成する
   *
   * @param {object} data
   * @returns {EntityList}
   */
  public static fromJS(data: object): EntityList {
    const entityList = immutable.fromJS(data).withMutations((entityList: immutable.Map<string, object>) => {
      return entityList.updateIn(['entities'], (entities: immutable.Map<string, object>) => entities.map((entity: object) => Entity.fromJS(entity)));
    });

    return new EntityList(entityList);
  }

  /**
   * ユーザID を抽出して返す
   * 重複したものは排除する
   *
   * @returns {immutable.Set<number>}
   */
  public get userIds(): immutable.Set<number> {
    return this.entities
      .map((entity: Entity) => entity.value.userId)
      .valueSeq()
      .toSet();
  }

  /**
   * エンティティのリストが空かどうかを返す
   *
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.entities.size === 0;
  }

  /**
   * @param {EntityList} categoryList
   * @returns {EntityList}
   */
  public mergeList(commentList: EntityList): EntityList {
    return this.mergeIn(['entities'], commentList.entities).mergeIn(['result'], commentList.result);
  }

  /**
   * @param {Entity} entity
   * @returns {EntityList}
   */
  public replaceEntity(entity: Entity): EntityList {
    return this.updateIn(['entities'], (entities: immutable.Map<string, Entity>) => entities.set(entity.identifier.toString(), entity));
  }

  /**
   * @param {number} commentId
   * @returns {Entity}
   */
  public getEntity(commentId: number): Entity {
    return this.entities.get(commentId.toString(), new Entity());
  }

  /**
   * @param {number[]} commentIds
   * @returns {immutable.List<Entity>}
   */
  public getEntities(commentIds?: number[]): immutable.List<Entity> {
    const commentIdList = commentIds ? immutable.List(commentIds) : this.result;

    return commentIdList.map((commentId: number) => this.getEntity(commentId));
  }
}
