import { RequestConfig } from '../../libs/axios';
import ConfirmApi from './ConfirmApi';
import DisabledPostCommentApi from './DisabledPostCommentApi';
import FetchApi from './FetchApi';
import FetchListApi from './FetchListApi';
import FetchMyListApi from './FetchMyListApi';
import SearchApi from './SearchApi';
import StoreApi from './StoreApi';
import * as Accesses from '../accesses';

export interface Api {
  confirm: () => ConfirmApi;
  store: () => StoreApi;
  fetch: () => FetchApi;
  fetchList: () => FetchListApi;
  fetchMyList: () => FetchMyListApi;
  fetchRanking: () => Accesses.FetchRankingApi;
  search: () => SearchApi;
  disabledPostComment: () => DisabledPostCommentApi;
}

export const Api = (requestConfig: RequestConfig): Api => {
  const accessesApi = Accesses.Api(requestConfig);

  return {
    confirm: () => new ConfirmApi(requestConfig),
    store: () => new StoreApi(requestConfig),
    fetch: () => new FetchApi(requestConfig),
    fetchList: () => new FetchListApi(requestConfig),
    fetchMyList: () => new FetchMyListApi(requestConfig),
    fetchRanking: () => accessesApi.fetchRanking(),
    search: () => new SearchApi(requestConfig),
    disabledPostComment: () => new DisabledPostCommentApi(requestConfig),
  };
};
